import React from "react";
import "./UserCentric.scss";

function UserCentric() {
  return (
    <div className="usercentric-section section-gap">
      <div className="container">
        <div
          className="card-section card-deco"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
            User Centric = ROI-Centric
          </h2>

          <p className="sub-title" data-aos="zoom-in" data-aos-once="true">
            Beyond the creation of your website, taking the effort to properly
            understand your target audience will pay you.
          </p>

          <p className="content" data-aos="zoom-in" data-aos-once="true">
            Those insights will help you become a better businessperson and
            marketer. We've taught user-centric thinking to hundreds of clients,
            and we'd love to demonstrate how simple it is for you.
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserCentric;
