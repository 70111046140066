import "./App.css";
import Navbar from "./component/Navbar/Navbar";
import "../src/styles/main.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./component/Footer/Footer";
import Aboutus from "./component/Aboutus/Aboutus";
import Team from "./component/Team/Team";
import Home from "./component/Home/Home";
import RequestQuotation from "./component/RequestQuotation/RequestQuotation";
import Products from "./component/Products/Products";
import WebDevelopment from "./component/WebDevelopment/WebDevelopment";
import Mobileapp from "./component/MobileApp/Mobileapp";
import AssuranceService from "./component/AssuranceService/AssuranceService";
import UserDevelopment from "./component/UserDevelopment/UserDevelopment";
import CloudService from "./component/CloudService/CloudService";
import ViableProduct from "./component/ViableProduct/ViableProduct";
import AgileDevelopment from "./component/AgileDevelopment/AgileDevelopment";
import OurWorks from "./component/OurWorks/OurWorks";
import PageNotFound from "./component/PageNotFound/PageNotFound";
import TabPanel from "./component/TabPanel/TabPanel";
import Stepper from "./component/Stepper/Stepper";
import StepperFirst from "./component/StepperFirst/StepperFirst";
import StepperSecond from "./component/StepperSecond/StepperSecond";
import StepperThird from "./component/StepperThird/StepperThird";
import Quotation from "./component/Quotation/Quotation";
import Careers from "./component/Careers/Careers";
import Qa from "./component/SeniorQa/SeniorQa";
import Offer from "./component/Offer/Offer";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import PrivacyPolicy from "./component/PrivacyPolicy/PrivacyPolicy";
import JobPositions from "./component/JobPositions/JobPositions";
import { ContactUs } from "./component/Contactus/Contactus";

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
});
  return (
    <Router>
      <div className="App">
        <div className="middlepage-section">
          <Navbar />
        </div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/senior-qa">
            <Qa />
          </Route>

          <Route exact path="/best-offer">
            <Offer />
          </Route>

          <Route exact path="/introduction">
            <Aboutus />
          </Route>

          <Route exact path="/quot">
            <Offer />
          </Route>

          <Route exact path="/privacypolicy">
            <PrivacyPolicy />
          </Route>

          <Route exact path="/works">
            <OurWorks />
          </Route>

          <Route exact path="/allworks">
            <OurWorks />
          </Route>

          {/* <Route exact path="/Team">
            <Team />
          </Route> */}
          <Route exact path="/products">
            <Products />
          </Route>
          <Route exact path="/webdevelopment">
            <WebDevelopment />
          </Route>

          <Route exact path="/mobileapp">
            <Mobileapp />
          </Route>

          <Route exact path="/assurance-services">
            <AssuranceService />
          </Route>

          <Route exact path="/userDevelopment">
            <UserDevelopment />
          </Route>

          <Route exact path="/cloud-service">
            <CloudService />
          </Route>

          <Route exact path="/viable-products">
            <ViableProduct />
          </Route>

          <Route exact path="/agile-development">
            <AgileDevelopment />
          </Route>

          <Route exact path="/ourworks">
            <OurWorks />
          </Route>

          <Route exact path="/pagenotfound">
            <PageNotFound />
          </Route>

          <Route exact path="/tab-panel">
            <TabPanel />
          </Route>

          <Route exact path="/stepper">
            <Stepper />
          </Route>

          <Route exact path="/stepper-first">
            <StepperFirst />
          </Route>

          <Route exact path="/stepper-second">
            <StepperSecond />
          </Route>

          <Route exact path="/stepper-third">
            <StepperThird />
          </Route>

          <Route exact path="/quotation">
            <Quotation />
          </Route>

          <Route exact path="/contact-us">
            <ContactUs />
          </Route>

          <Route exact path="/job-Positions">
            <JobPositions />
          </Route>

          <Route exact path="/careers">
            <Careers />
          </Route>
        </Switch>
        <RequestQuotation />

        <Footer />
      </div>
    </Router>
  );
}

export default App;
