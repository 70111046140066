import React from "react";

function General() {
  return (
    <div className="privacy-section section-gap text-init">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Privacy Policy
        </h2>
        <h6 data-aos="zoom-in" data-aos-once="true">
          General
        </h6>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          We at Axios Softwork Pvt. Ltd. are committed to protecting the privacy
          of individuals who visit our website (“Visitors”) and individuals who
          register to use the Axios System Services on behalf of Axios’s Clients
          (“Users”) (collectively “you” or “your”). privacy is important to us.
          This privacy policy is intended to provide our valued
          customers/visitors confidence and security of the personal information
          that we have obtained.
        </p>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          However, Axios will not be held responsible for the data you have
          provided to third-party applications or websites linked with us or
          your browser. We strongly recommend that you review the privacy policy
          of such parties before proceeding forward.
        </p>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          Please read the full piracy policy to get complete details on how we
          collect, use and handle your personal information.
        </p>
        <h6 data-aos="zoom-in" data-aos-once="true">
          1.Collection of Personal Information
        </h6>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          While using our services, you might be asked to fill in Personally
          Identifiable Information such as your name, contact number, e-mail
          address, date of birth, address, and more. By providing us with such
          information, you will need to grant us permission to use the
          information as stated in this policy.
        </p>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          Our clients are able to withdraw the consent anytime. Email us or
          contact our office and we will destroy your collected information
          within ten days of the request.
        </p>

        <h6 data-aos="zoom-in" data-aos-once="true">
          2.How Do We Collect Information
        </h6>

        <p className="content" data-aos="zoom-in" data-aos-once="true">
          Our main source for your Personally Identifiable Information is our
          online requisition form. Apart from that, we might also collect your
          information from our blog, social media channels, and subscriptions to
          our newsletters.
        </p>

        <p className="content" data-aos="zoom-in" data-aos-once="true"></p>
      </div>
    </div>
  );
}

export default General;
