import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import "./StepperFirst.scss";

const projectList = [
  {
    image: require("../../images/laptop.png").default,
    title: "Web Development",
  },
  {
    image: require("../../images/mobile.png").default,
    title: "Mobile Development",
  },
  {
    image: require("../../images/product.png").default,
    title: "Product Design",
  },
  {
    image: require("../../images/msgbox.png").default,
    title: "I'm not sure, I need advice",
  },
];

const StepperFirst = (props) => {
  const [activeItem, setActiveItem] = useState([]);
  const formik = useFormikContext();

  const setFieldValue = formik.setFieldValue;
  const { setDisableBtn } = props;

const handleCheck = (item) => {

  if(item.title==="I'm not sure, I need advice"){
   const newArr=[]
    newArr.push(item)
    setActiveItem(newArr)
    return
  }
  if(activeItem.find(val=>val.title==="I'm not sure, I need advice")){
    const reqIndex=activeItem.findIndex(val=>val.title="I'm not sure, I need advice")
    activeItem.splice(reqIndex,1)
  }

  setActiveItem([...activeItem, item]);
  if (activeItem.includes(item)) {
    var tempItem = activeItem.filter(
      (f) => f.title !== item.title
    );
    setActiveItem(tempItem);
  }
}

  useEffect(() => {
    if (activeItem.length > 0) {
      setDisableBtn?.(false);
    } else setDisableBtn?.(true);
    setFieldValue("development", activeItem);
  }, [activeItem, setFieldValue, setDisableBtn]);

  return (
    <div className="step-one section-gap">
      <div className="container">
        <div className="head-section">
        <h2 className="main-title ">
          How can we help you start your new digital project?
        </h2>
        </div>
        <p className="content">You can select more than one answer.</p>
        <div className="stepper-content">
          <div className="row">
            {projectList?.map((item, index) => {
              const exists = activeItem.find(
                (itm) => itm?.title === item.title
              );
              return (
                <div
                  className="col-md-6 col-6"
                  key={index}
                  onClick={()=> handleCheck(item)}
                >
                  <input
                    type="checkbox"
                    id={`myCheckbox-${index}`}
                    name={item.title}
                    value={item.title}
                    checked={activeItem.includes(item) ? true : false}
                  />
                  <label
                    htmlFor={`myCheckbox-${index}`}
                    className={Boolean(exists) ? "checked" : ""}
                  >
                    <div className="card-section layer-bg-maroon">
                      <img className="images" src={item.image} alt="images" />
                      <p className="checkbox-content ">{item.title}</p>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default StepperFirst;