import React from "react";
import CuServiceAgile from "../CuServiceAgile/CuServiceAgile";
import SubBannerAgile from "../SubBannerAgile/SubBannerAgile";
import "../Home/Home.scss";
import Agile from "../Agile/Agile";
import Axios from "../Axios/Axios";
import OurAgile from "../OurAgile/OurAgile";
import MetaConfigure from "../Helmet/Helmet";

function AgileDevelopment() {
  return (
    <div className="banner-overflow">
      <MetaConfigure title="AgileDevelopment-AxiosSoftworks" />

      <SubBannerAgile />
      <CuServiceAgile />
      <Agile />
      <Axios />
      <OurAgile />
    </div>
  );
}

export default AgileDevelopment;
