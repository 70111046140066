import React, { Component } from 'react'
import './TeamDetails.scss'
import Slider from 'react-slick'

class TeamDetails extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      // autoplay: true,
      // speed: 2000,
      // autoplaySpeed: 2000,
      // pauseOnHover: false,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <div className="team-details">
        <div className="slider-section">
          <div className="container">
            <h1
              className="section-gap export"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              Board Members
            </h1>
            <Slider {...settings}>
              <div>
                <div className="team-details-content">
                  <div className="team-details-section">
                    <img
                      src={require('../images/team/sabin.png').default}
                      className="bg-color"
                      alt="img"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    />
                    <div className="social">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/salnpskhl/"
                      >
                        <i class="bg-color bi bi-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/avisdaps"
                      >
                        <i class="bg-color bi bi-facebook"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/sabin-pasakhala-72569115a/"
                      >
                        <i class="bg-color bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>

                  <div
                    className="team-info"
                    data-aos="zoom- in"
                    data-aos-once="true"
                  >
                    <h2 className="main-title ">Sabin Pasakhala</h2>
                    <p className="content">Chief Executive Officer</p>
                    <p className="content">Co-Founder</p>
                  </div>
                </div>
              </div>

              <div>
                <div className="team-details-content">
                  <div className="team-details-section">
                    <img
                      src={require('../images/team/sunil.png').default}
                      className="bg-color"
                      alt="img"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    />
                    <div className="social">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/ericsunil/"
                      >
                        <i class="bg-color bi bi-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/eric.soron.5"
                      >
                        <i class="bg-color bi bi-facebook"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/sunil-hyaunmikha-46471998/"
                      >
                        <i class="bg-color bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="team-info"
                    data-aos="zoom- in"
                    data-aos-once="true"
                  >
                    <h2 className="main-title "> Sunil Hyaunmikha </h2>
                    <p className="content">Chief Operation Officer</p>
                    <p className="content">Co-Founder</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="team-details-content">
                  <div className="team-details-section">
                    <img
                      src={require('../images/team/subash.png').default}
                      className="bg-color"
                      alt="img"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    />
                    <div className="social">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/subashpasachhe/"
                      >
                        <i class="bg-color bi bi-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/subashpasachhe"
                      >
                        <i class="bg-color bi bi-facebook"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/subash-pasachhe-b043521b7/"
                      >
                        <i class="bg-color bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="team-info"
                    data-aos="zoom- in"
                    data-aos-once="true"
                  >
                    <h2 className="main-title "> Subash Pasachhe </h2>
                    <p className="content">Chief Technology Officer</p>
                    <p className="content">Co-Founder</p>
                  </div>
                </div>
              </div>

              <div>
                <div className="team-details-content">
                  <div className="team-details-section">
                    <img
                      src={require('../images/team/sundar1.png').default}
                      className="bg-color"
                      alt="img"
                    />
                    <div className="social">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com"
                      >
                        <i class="bg-color bi bi-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/sundarm1"
                      >
                        <i class="bg-color bi bi-facebook"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/sundar-machamasi-571718b9/"
                      >
                        <i class="bg-color bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="team-info">
                    <h2 className="main-title ">Sundar Machamasi</h2>
                    <p className="content">Project Manager / QA Head</p>
                    <p className="content">Co-Founder</p>
                  </div>
                </div>
              </div>

              <div>
                <div className="team-details-content">
                  <div className="team-details-section">
                    <img
                      src={require('../images/team/sudin.png').default}
                      alt="img"
                      className="bg-color"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    />
                    <div className="social">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="
                        https://www.instagram.com/sudin.mdrr/"
                      >
                        <i class="bg-color bi bi-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/SuDean11"
                      >
                        <i class="bg-color bi bi-facebook"></i>
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/sudin-manandhar-b132a413a/"
                      >
                        <i class="bg-color bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className="team-info"
                    data-aos="zoom- in"
                    data-aos-once="true"
                  >
                    <h2 className="main-title "> Sudin Manandhar</h2>
                    <p className="content">Co-Founder</p>
                  </div>
                </div>
              </div>
            </Slider>

            <div>
              <h1
                className="main-title mb-30"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Operations
              </h1>
              <div className="row  justify-content-md-center">
                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/anjila.png').default}
                          className="bg-color"
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/anjilaa_manandhar/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>

                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/anjila.manandhar.7"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/anjila-manandhar-8973201b7/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">Anjila Manandhar</h2>
                      <p className="content">Program Manager </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/charu.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/charu_manandhar/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/charu.mdr.5"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/charu-manandhar-537422207/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">Charu Manandhar</h2>
                      <p className="content">Product Manager </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="team-details-content mt-40">
              <h1
                className="main-title mb-30"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Software Developers
              </h1>
              <div className="row">
                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/subham.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/subham.shrestha.90"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/subham-shrestha-89903b194/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">SUBHAM SHRESTHA</h2>
                      <p className="content"> Frontend Engineer</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/pratima.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/awapratima/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/pratimaawa"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/pratima-awa-2b8759202/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">PRATIMA AWA</h2>
                      <p className="content"> Frontend Developer</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/riya.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/reea_sth/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/reyastha"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/riya-shrestha-3001ba135/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">RIYA SHRESTHA</h2>
                      <p className="content">UX / UI Designer</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/sweta.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/xweta.mdr"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/sweta-manandhar-212276211/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">Sweta Manandhar </h2>
                      <p className="content"> Frontend Developer</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/bijay-D.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/i_bjay/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/bj.dongol.1"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/bijay-dongol-17b364143/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title">BIJAY DONGOL</h2>
                      <p className="content"> Backend Developer</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/anuj.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/anewz_ranjit"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/profile.php?id=100003855494411"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/anuj-ranjit-a273a6182/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title">Anuj Ranjit</h2>
                      <p className="content"> Backend Developer</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/prashant.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/prashant.shrestha3"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/prashant-shrestha-98b7ba147/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">Prashant SHRESTHA</h2>
                      <p className="content"> Frontend Engineer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="team-details-content mt-40">
              <h1
                className="main-title mb-30"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Marketing Team
              </h1>
              <div className="row">
                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/aagaman.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/aagamingo/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/aagaman.sewacharya"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/aagaman-sewacharya-27801a229/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">Aagaman Sewacharya</h2>
                      <p className="content"> Technology support </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/roshika.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/roshikarajbanshi_/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/roshika.rajbanshi.1"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/roshika-rajbanshi-56627a243/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">Roshika Rajbanshi</h2>
                      <p className="content">Call Representive</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/Nirisha.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/nirshu_bade/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/nirisha.bade"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/nirisha-bade-b75459243/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">Nirisha Bade </h2>
                      <p className="content"> Call Representive</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="team-details-content mg-0">
                    <div className="team-details-section">
                      <div>
                        <img
                          src={require('../images/team/Bipashna.png').default}
                          alt="img"
                          data-aos="zoom-in"
                          data-aos-once="true"
                        />
                        <div className="social">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/"
                          >
                            <i class="bg-color bi bi-instagram"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/bipana.maharjan.58"
                          >
                            <i class="bg-color bi bi-facebook"></i>
                          </a>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/bipashna-maharjan-880a87215/"
                          >
                            <i class="bg-color bi bi-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="team-info"
                      data-aos="zoom- in"
                      data-aos-once="true"
                    >
                      <h2 className="main-title ">Bipashna Maharjan </h2>
                      <p className="content"> Social media manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamDetails
