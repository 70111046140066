import React, { useState, useEffect } from 'react'
import './JobPositions.scss'
import Modal from 'react-awesome-modal'
import Qa from '../../component/SeniorQa/SeniorQa'
import { getJobs } from '../Api/GetApi'

function JobPositions() {
  const [jobOffer, setJobOffer] = useState()
  const [isVisible, setIsVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState()

  useEffect(() => {
    getJobs()
      .then((res) => {
        console.log(res)
        setJobOffer(res.data)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  const closeModal = () => {
    setIsVisible(false)
  }
  const handleBack = () => {
    closeModal()
  }
  return (
    <div id="jobpositions" className="position-section section-gap">
      <div className="container">
        <Modal
          visible={isVisible}
          effect="fadeInUp"
          className="pop-up"
          onClickAway={() => closeModal()}
        >
          <div className="title-flex">
            <h4 className="head-title small">{selectedItem?.title}</h4>
            <span className="cross" onClick={() => closeModal()}>
              X
            </span>
          </div>
          <Qa jobDescription={selectedItem} handleBack={handleBack}/>
        </Modal>
        <h2 className="main-title text-left">Open Job Positions</h2>
        <div className="mg-top">
          <div className="row">
            {jobOffer?.jobs?.length > 0 ? (
              <>
                {jobOffer?.jobs?.map((item) => {
                  return (
                    <div className="col-md-2 card-width" key={item}>
                      <div className="card-section">
                        <h2 className="sub-title">{item.title}</h2>
                        <div className="d-flex ">
                          <i class="bi bi-geo-alt"></i>
                          <p className="content">{item.job_type}</p>
                        </div>

                        <p className="content-item">
                          No. of opening:{item.no_of_opening}
                        </p>
                        
                        <div className="d-flex prime-color">
                          <p
                            className="content"
                            type="button"
                            onClick={() => {
                              setIsVisible(true)
                              setSelectedItem(item)
                            }}
                          >
                            See job offer <i class="bi bi-arrow-right"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <div>
                <img
                  className="nojob-img"
                  src={require('../../images/axios-nojob.png').default}
                  alt="nojob"
                />
                <p>No Job positions available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobPositions
