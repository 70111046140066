import React from 'react'
import './BenefitsOfMob.scss'

function BenefitsOfMob() {
  window.scrollTo(0, 0)
  return (
    <div className="BenefitsMob-section section-gap">
      <div className="container">
        <h2
          className="main-title mb-15"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Benifits of Mobile App Development
        </h2>
        <p className="benefits-content content">
          Axios will help you construct whatever you desire. Our developers will
          use the latest mobile  technology to revolutionize your business and
          make it more efficient.
        </p>

        <div className="row">
          <div
            className="col-md-12 col-lg-6  col-xl-6 "
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightpink lightpink-bg ">
              <div className="outer-img-card">
                <div className="img-card ">
                  <img
                    className="logo-img"
                    src={require('../../images/appdev/Frame.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title "> Integrate Mobile with Web</h2>
                <p className="content">
                  Mobile integration integrates various apps and devices. It
                  aids in improving the flexibility and efficiency. If you
                  already have a mobile device, we will assist to access your
                  web application.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section bg-lightblue lightblue-bg">
              <div className="weserve-content">
                <div className="outer-img-card">
                  <div className="img-card  ">
                    <img
                      className="logo-img"
                      src={require('../../images/appdev/Frame (1).png').default}
                      alt="img"
                    />
                  </div>
                </div>
                <h2 className="sub-title ">
                  Build for iOS and Android Platform
                </h2>
                <p className="content">
                  Building apps for both platforms gives you more options, a
                  better user experience, and more functionality. We'll deliver
                  your app to market as soon and efficiently as possible.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightgreen lightgreen-bg">
              <div className="weserve-content">
                <div className="outer-img-card">
                  <div className="img-card ">
                    <img
                      className="logo-img"
                      src={require('../../images/webdev/Frame (2).png').default}
                      alt="img"
                    />
                  </div>
                </div>

                <h2 className="sub-title ">Streamline your Business Process</h2>
                <p className="content">
                  Custom-made business applications are the most effective
                  method to boost workplace productivity. A well-designed
                  business app enhances communication, automates routine
                  processes, and is portable
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightpurple lightpurple-bg  ">
              <div className="weserve-content">
                <div className="outer-img-card">
                  <div className="img-card  ">
                    <img
                      className="logo-img"
                      src={require('../../images/appdev/Frame (2).png').default}
                      alt="img"
                    />
                  </div>
                </div>

                <h2 className="sub-title ">Create a Stand-Alone Mobile App</h2>
                <p className="content">
                  Axios can create an app that gives your consumers a better
                  experience. We know how to make your valued concept into
                  reality, whether you're a start-up or an established company.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BenefitsOfMob
