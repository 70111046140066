import React from "react";
import SubBannerCloud from "../SubBannerCloud/SubBannerCloud";
import CuServiceCloud from "../CuServiceCloud/CuServiceCloud";
import Timeline from "../Timeline/Timeline";
import "../Home/Home.scss";
import MetaConfigure from "../Helmet/Helmet";

function CloudService() {
  return (
    <div className="banner-overflow">
      <MetaConfigure title="CloudService-AxiosSoftworks" />

      <SubBannerCloud />
      <CuServiceCloud />
      <Timeline />
    </div>
  );
}

export default CloudService;
