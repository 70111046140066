import React from "react";
import "./QAnswer.scss";

function QAnswer() {
  return (
    <div className="QAns-section section-gap text-init">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Why an MVP ?
        </h2>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          We simply create the bare minimum of functionality to attract early
          users, using the lean startup model. This is referred to as a Minimum
          Viable Product (MVP) . The features to prioritize are determined by
          our user stories and input from user testing.
        </p>
      </div>
    </div>
  );
}

export default QAnswer;
