import React from "react";

function Disclosure() {
  return (
    <div className="privacy-section section-gap text-init">
      <div className="container">
        <h6 data-aos="zoom-in">3.Disclosure of Personal Information</h6>
        <p className="content" data-aos="zoom-in">
          Your Personally Identifiable Information will not be shared with any
          third parties unless you have consented to disclose your information
          to other companies. We may provide the gathered information to our
          employees or agents in case of urgency. Should you breach our terms
          and condition or we are bound to disclose your data because of any
          legal obligation, we might provide your information to the relevant
          authorities or government bodies.
        </p>
        <h6 data-aos="zoom-in">4.Security of Your Information</h6>
        <p className="content" data-aos="zoom-in">
          We count security as the most important component. We commence the
          best security steps to keep your data safe, including SSL technology.
        </p>

        <p className="content" data-aos="zoom-in">
          If you have additional queries regarding security issues email us at{" "}
          <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCKCJDkXjnFjtJRQHPLhQNngZnGrkzhtGGCFvBvKnLDFhkwLzVQXjgfZwPPLjvbXBHvKbQDq"> info@axiossoftwork.com </a>
        </p>

        <p className="content" data-aos="zoom-in"></p>
      </div>
    </div>
  );
}

export default Disclosure;
