import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import "./StepperSecond.scss";

const skillList = [
  {
    title: "UI/UX Design",
  },
  {
    title: "Full Project",
  },
  {
    title: "Quality Assurance",
  },
  {
    title: "Backend Development",
  },
  {
    title: "Frontend Development",
  },
  {
    title: "I'm not sure, I need advice",
  },
];

function StepperSecond({ setDisableBtn = (num) => {} }) {
  const [activeItem, setActiveItem] = useState([]);
  const { setFieldValue } = useFormikContext();

const handleCheck = (item) => {
  
  if(item.title==="I'm not sure, I need advice"){
    const newArr=[]
    newArr.push(item)
    setActiveItem(newArr)
    return
  }
  if(activeItem.find(val=>val.title==="I'm not sure, I need advice")){
    const reqIndex=activeItem.findIndex(val=>val.title="I'm not sure, I need advice")
    activeItem.splice(reqIndex,1)
  }
  
  setActiveItem([...activeItem, item]);
  if (activeItem.includes(item)) {
    var tempItem = activeItem.filter(
      (f) => f.title !== item.title
    );
    setActiveItem(tempItem);
  }
}

  useEffect(() => {
    if (activeItem.length > 0) {
      setDisableBtn(false);
    } else setDisableBtn(true);
    setFieldValue("design", activeItem);
  }, [activeItem, setDisableBtn, setFieldValue]);

  return (
    <div className="step-two">
      <div className="container">
        <h2 className="main-title ">
          Are you looking for any particular<p>skills?</p>
        </h2>
        <p className="content">You can select more than one answer.</p>
        <div className="stepper-content">
          <div className="row">
            {skillList?.map((item, index) => {
              
              
              return (
                <div
                  className="col-md-6 col-6"  
                  key={index}
                  onClick={()=>{handleCheck(item)}}
                >
                  <input
                    type="checkbox"
                    id="myCheckbox1"
                    checked={activeItem.includes(item) ? true : false}
                  />
                  <label htmlFor="myCheckbox1">
                    <div className="card-section layer-bg-maroon">
                      <p className="checkbox-content">{item.title}</p>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default StepperSecond;
