import React from "react";
import { Link } from "react-router-dom";
import "./ApplyQA.scss"

function ApplyQA() {
  return (
    <div className="apply-section section-gap">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Nothing suitable ?
        </h2>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          No worries, we still want to get to know you. Send us your CV, tell us
          about your passion, and how you will make our Axios world better.
        </p>

        <Link to="/quot">
          <button className="main-btn" data-aos="zoom-in" data-aos-once="true">
            Apply Proactively
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ApplyQA;
