import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./WorkSlider.scss";

class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      adaptiveHeight: false,
      adaptiveHeight: false,
      slidesToScroll: 1,
    };
    return (
      <div className="slider-section">
        <Slider className="slider" adaptiveHeight={false} {...settings}>


          <div className="row d-flex">
            <div className="col-md-6">
              <div
                className="webapp-img"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <img
                  src={require("../../images/Cover.png").default}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="webapp-content text-init">
                <p
                  className="content-text"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  Web app
                </p>
                <h1
                  className="main-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  Najik.com
                </h1>
                <p className="content" data-aos="zoom-in" data-aos-once="true">
                  {" "}
                  Najik.com is a one-stop eCommerce platform company based in
                  Jhamshikhel, Lalitpur. Our team of professionals specializes
                  in all kinds of B2C, B2B, Merchandise, and Services. It is
                  Nepal's largest online B2B marketplace, connecting Businesses,
                  Shop Owners and Suppliers.
                </p>

                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://najik.com/"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <button className="main-btn">Check here</button>
                </a>
              </div>
            </div>
          </div>

          <div className="row d-flex">
              <div className="col-md-6">
                <div className="webapp-img">
                  <img
                    src={require("../../images/2nd (2).png").default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="webapp-content text-init">
                  <p className="content-text">Web App</p>
                  <h1 className="main-title">Bolpatra Nepal</h1>
                  <p className="content">
                  Nepal’s best tender portal that provides Information for the
                Latest Nepal Tenders , Online Tenders , online tender
                information portal.
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://bolpatranepal.com/"
                  >
                    <button className="main-btn">Check here</button>
                  </a>
                </div>
              </div>
            </div>

          

          <div className="row d-flex">
              <div className="col-md-6">
                <div className="webapp-img">
                  <img
                    src={require("../../images/auroraestoreaxios-min.png").default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="webapp-content text-init">
                  <p className="content-text">E-commerce</p>
                  <h1 className="main-title">Aurora Estore</h1>
                  <p className="content">
                  AuroraEstore is your ultimate destination for genuine Kbeauty brands within Nepal. They directly import the most adored Kbeauty brands from Korea, ensuring that all our products are completely authentic.
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://auroraestore.com/"
                  >
                    <button className="main-btn">Check here</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-6">
                <div className="webapp-img">
                  <img
                    src={require("../../images/Cover2.png").default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="webapp-content text-init">
                  <p className="content-text">CMS</p>
                  <h1 className="main-title">Edfluent</h1>
                  <p className="content">
                    Edfluent is a Professional English proofreading and
                    translation service that provides Wide range of services,
                    professionals, high ethical standards, satisfaction to the
                    customers. You can select different plans and package for
                    the same
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://demo.edfluent.jp/"
                  >
                    <button className="main-btn">Check here</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-6">
                <div className="webapp-img">
                  <img
                    src={require("../../images/Cover3.png").default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="webapp-content text-init">
                  <p className="content-text">Web app</p>
                  <h1 className="main-title">Virtual Eye</h1>
                  <p className="content">
                    With a few finger swipes on your phone or clicks on your
                    laptop, It provides a diagnosis, a report and a management
                    plan for your eye condition. With Virtual Eye Consultant,
                    you can save your time, effort and money. You will receive
                    expert ophthalmologist opinions without the risk of
                    encountering an inexperienced doctor at a hospital, and It
                    generates the results within 24 hours after receiving your
                    data.
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://virtualeye.net/"
                  >
                    <button className="main-btn">Check here</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-6">
                <div className="webapp-img">
                  <img
                    src={require("../../images/Cover4.png").default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="webapp-content text-init">
                  <p className="content-text">Web app</p>
                  <h1 className="main-title">Surgi Gas</h1>
                  <p className="content">
                    SurgiGas is a mobile platform that links anesthesiologists
                    and surgeons. A convenient way to find an anesthetist for a
                    surgery procedure and assist anesthetists generate more
                    revenue.
                  </p>
                  <Link to="/allworks">
                    <button className="main-btn">Check here</button>
                  </Link>
                </div>
              </div>
            </div>
        </Slider>
      </div>
    );
  }
}

export default SimpleSlider;
