import axios from "axios";

export const domain = "https://nividweb.axiossoftwork.com/";
// export const domain =
//   "https://e6e1-2400-1a00-b060-5ba5-ad6a-55a-d723-a087.in.ngrok.io/";

const instance = axios.create({
  baseURL: domain,
  headers: {
    "content-type": "application/json",
  },
  //   withCredentails: true,
});

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
