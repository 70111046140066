import React from 'react'
import './DevEnvironmentAD.scss'

function DevEnvironmentAD() {
  return (
    <div className="dev-environmentAD-section section-gap">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Development Enviroment
        </h2>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          Axios's solutions are builts on modern and scalable architectures with
          robust, industry-standard technologies.
        </p>

        <div className="row">
          <div className="col-md-4 col-6">
            <div
              className="card-section min-height"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <div className="mid-img">
                <img
                  src={require('../../images/logos/android 2.png').default}
                  alt="img"
                />
                <div className="sub-title"> Android App Development </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-6">
            <div
              className="card-section min-height"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <div className="mid-img">
                <img
                  // src={require('../../images/react.png').default}
                  src={require('../../images/DElogos/flutter-r-bg.png').default}
                  alt="img"
                />

                <div className="sub-title"> Flutter</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6">
            <div
              className="card-section min-height"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <div className="mid-img">
                <img
                  src={require('../../images/DElogos/kotlin-r-bg.png').default}
                  // src={require('../../images/react.png').default}
                  alt="img"
                />

                <div className="sub-title"> Kotlin</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DevEnvironmentAD
