import React from 'react'
// import BannerPart from "../Particles/Particles";
import TsBannerPart from '../Particles/TsParticles'
import './Banner.scss'
function Banner() {
  return (
    <div className="banner-rel">
      <div className="banner-section">
        {/* <BannerPart /> */}
        <TsBannerPart />

        <div className="banner-content">
          <h2 className="main-title">
            Innovation For <span>Excellence</span>
          </h2>

          <p className="content">Hub for best of breed Technical innovation</p>
        </div>

        <a href="#weserve">
          <div className="banner-icon">
            <i class="bi bi-arrow-down"></i>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Banner
