import React from 'react'
import './Platform.scss'

function Platform() {
  return (
    <div className="platform-section section-gap">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex">
              <div className="platform-content">
                <div className="head-section">
                  <h2
                    className="main-title section-gap"
                    data-aos="zoom-in"
                    data-aos-once="true"
                  >
                    Our Development Platforms
                  </h2>
                </div>
                {/* <h2
                  className="main-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  Platforms
                </h2> */}
                <p className="content" data-aos="zoom-in" data-aos-once="true">
                  We strive to stay up to date by implementing the  latest
                  frameworks and technologies.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
            <img src={require('../../images/language.png').default} alt="img" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Platform
