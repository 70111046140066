import React from "react";
import AppService from "../AppService/AppService";
import DevEnvironmentAD from "../DevEnvironmentAD/DevEnvironmentAD";
import DevProcess from "../DevProcess/DevProcess";
import SubBannerAD from "../SubBannerAD/SubBannerAD";
import BenefitsOfMob from "../BenefitsOfMob/BenefitsOfMob";
import "../Home/Home.scss";
import MetaConfigure from "../Helmet/Helmet";

function Mobileapp() {
  return (
    <div className="banner-overflow">
      <MetaConfigure title="MobileApp-AxiosSoftworks" />

      <SubBannerAD />
      <AppService />
      <BenefitsOfMob />
      <DevEnvironmentAD />
      <DevProcess />
    </div>
  );
}

export default Mobileapp;
