import React from 'react'
import './SubBannerAD.scss'
function SubBannerAD() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
        <div className="top-section">
          <div className="top-text">
            <h2 className="main-title">Mobile App Development</h2>
            <p className="content">
              Focused on solving complex problems on Mobile with clear and
              effective solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubBannerAD
