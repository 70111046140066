import React from "react";
import { Link } from "react-router-dom";
import SimpleSlider from "../WorkSlider/WorkSlider";
import "./RecentWorks.scss";
function RecentWorks() {
  return (
    <div className="work-section section-gap">
      <div className="container">
        <div className="top-section">
          <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
            Recent Works
          </h2>
          <Link to="/allworks">
            <button
              className="secondary-btn"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              View all Works
            </button>
          </Link>
        </div>

        <SimpleSlider />
      </div>
    </div>
  );
}

export default RecentWorks;
