import React from 'react'
import './BenefitsOfCustomer.scss'

function BenefitsOfCustomer() {
  window.scrollTo(0, 0)
  return (
    <div className="Benefits-section section-gap">
      <div className="container">
        <h2
          className="main-title mb-15"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Benefits of Customer Web Development
        </h2>
        <p
          data-aos="zoom-in"
          data-aos-once="true"
          className="benefits-content content"
        >
          There are clearly benefits both for custom software and
          "off-the-shelf" solutions. By choosing custom software development
          from Axios, you'll enjoy numerous advantages of a custom system,
          tailored for your business.
        </p>

        <div className="row">
          
          <div
            className="col-md-12 col-lg-6  col-xl-6 "
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightpink lightpink-bg ">
              <div className="outer-img-card">
                <div className="img-card ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame.png').default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="weserve-content">
                <h2 className="sub-title "> Full Ownership</h2>
                <p className="content">
                  Axios customers own 100% of their software, so you'll never
                  pay subscription or royalty fees. Want to add another feature
                  or more users? No problem; you have all the control.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section bg-lightblue lightblue-bg">
              <div className="outer-img-card">
                <div className="img-card  ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame (1).png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Full Flexibility</h2>
                <p className="content">
                  With a custom-built Axios solution, you can do whatever you
                  want whenever you want with your product. That means your
                  software can grow and change with your business in a good way
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightgreen lightgreen-bg">
              <div className="outer-img-card">
                <div className="img-card ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame (2).png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Competitive Advantages</h2>
                <p className="content">
                  A custom-built Axios solution, unlike "off-the-shelf"
                  software, may improve your company's performance and serve as
                  a valuable IP asset, giving you a competitive edge.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightpurple lightpurple-bg  ">
              <div className="outer-img-card">
                <div className="img-card  ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame (3).png').default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="weserve-content">
                <h2 className="sub-title ">Technology Independent</h2>
                <p className="content">
                  Axios is a technology-agnostic company. In other words, we
                  don't sell or promote specific technologies, so we're 100%
                  focused on our customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BenefitsOfCustomer
