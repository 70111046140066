import React from "react";
import "./SubBannerWD.scss";

function SubBannerWD() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
      <div className="top-section">
          <div className="top-text">
              <h2 className="main-title"> Custom Web Development</h2>
              <p className="content">
              Focused on solving complex problems on Web with clear and effective
              solutions.
              </p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default SubBannerWD;
