import React from "react";

function Agile() {
  return (
    <div className="QAns-section section-gap text-init">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          What is Agile?
        </h2>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          Agile refers to software development techniques and frameworks that
          stress iterative software delivery, quick and flexible change
          response, and using feedback to enhance user-focused results. When
          using an Agile approach to software development, you can expect a
          product that is planned, developed, and validated to reduce risk and
          maximize business value. Agile engineering techniques are becoming
          increasingly popular as a means of responding rapidly to market
          possibilities, speeding software development, and lowering costs.
        </p>
      </div>
    </div>
  );
}

export default Agile;
