import React from 'react'
import './DevProcess.scss'
function DevProcess() {
  return (
    <div className="devprocess-section section-gap">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Axios Development Process
        </h2>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          Software development, like any other manufacturing process, is built
          on logic, structure, and component responsibility.Only in this manner
          can high-quality software be produced. At Axios, we have all of them,
          and we know what we're doing at each stage of developing a succesful
          application.
        </p>

        <div className="devprocess-contain">
          <div className="dev-img">
            <img
              src={require('../../images/process.png').default}
              alt="img"
              data-aos="zoom-in"
              data-aos-once="true"
            />
          </div>
          <div className="row">
            <div
              className="col-md-4 col-lg-4"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-once="true"
            >
              <fieldset>
                <legend>01.</legend>
                <div className="devprocess-details mgr-3">
                  <h2 className="main-title">Idea</h2>
                  <p className="content">
                    Let us know what business objectives your new software
                    should meet.
                  </p>
                </div>
              </fieldset>
            </div>
            <div
              className="col-md-4 col-lg-4"
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-once="true"
            >
              <fieldset>
                <legend>02.</legend>
                <div className="devprocess-details mgr-3">
                  <h2 className="main-title">BUSINESS ANALYSIS</h2>
                  <p className="content">
                    We work together to figure out how it should fit into
                    existing workflows.
                  </p>
                </div>
              </fieldset>
            </div>
            <div className="col-md-4 col-lg-4">
              <fieldset>
                <legend>03.</legend>
                <div
                  className="devprocess-details mgr-3"
                  data-aos="fade-right"
                  data-aos-delay="500"
                  data-aos-once="true"
                >
                  <h2 className="main-title">UI DESIGN/ PROTOTYPING</h2>
                  <p className="content">
                    Our team develops concepts of software's appearance and
                    functionality.
                  </p>
                </div>
              </fieldset>
            </div>

            <div
              className="col-md-4 col-lg-4"
              data-aos="fade-left"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <fieldset className='sm-field mgl-2'>
                <legend>04.</legend>
                <div className="devprocess-details mgl-3">
                  <h2 className="main-title">DEVELOPMENT</h2>
                  <p className="content">
                    We take it one step at a time, ensuring that the solution is
                    of high quality.
                  </p>
                </div>
              </fieldset>
            </div>
            <div
              className="col-md-4 col-lg-4"
              data-aos="fade-left"
              data-aos-delay="300"
              data-aos-once="true"
            >
              <fieldset className='sm-field mgl-1'>
                <legend>05.</legend>
                <div className="devprocess-details mgl-3 ">
                  <h2 className="main-title">TESTING</h2>
                  <p className="content">
                    Our QA team ensures that the program performs as expected.
                  </p>
                </div>
              </fieldset>
            </div>

            <div
              className="col-md-4 col-lg-4"
              data-aos="fade-left"
              data-aos-delay="0"
              data-aos-once="true"
            >
              <fieldset className="mgl-3 sm-field">
                <legend>06.</legend>
                <div className="devprocess-details mgl-3">
                  <h2 className="main-title">DEPLOYMENT </h2>
                  <p className="content">
                    Users receives app, modules, updates, and fixes from our
                    developers.
                  </p>
                </div>
              </fieldset>
            </div>

            <div
              className="col-md-4 col-lg-4"
              data-aos="fade-right"
              data-aos-delay="0"
              data-aos-once="true"
            >
              <fieldset>
                <legend>07.</legend>
                <div className="devprocess-details mgr-3">
                  <h2 className="main-title">MAINTEINANCE AND SUPPORT</h2>
                  <p className="content">
                    Our team continues to monitor the solution in an ongoing
                    capacity.
                  </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DevProcess
