import React from "react";
import "./PageNotFound.scss";
function PageNotFound() {
  return (
    <div className="page-section section-gap">
      <div className="container">
        <h2 className="head-title">Oops !</h2>

        <h2 className="main-title">404 - Page not found </h2>
        <p className="content">
          The page you are looking for might has been removed had its name
          changed or is temporarily unavailable.
        </p>

        <button className="main-btn">Go back to Home page</button>
      </div>
    </div>
  );
}

export default PageNotFound;
