import React from 'react'
import './CustomService.scss'
function CustomService() {
  return (
    <div className="custom-service section-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="custom-web-section">
              <h2 className="sub-title" data-aos="zoom-in" data-aos-once="true">
                Custom Web
              </h2>
              <h2
                className="main-title"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                Development
              </h2>
              <p className="content" data-aos="zoom-in" data-aos-once="true">
                Axios creates its online solutions utilizing an Agile project
                management approach, which is often used in software development
                and in which needs and solutions emerge from the collaborative
                efforts to self-organizing and cross-functional teams as well as
                their clients.
              </p>
            </div>
          </div>
          <div className="col-md-6" data-aos="zoom-int">
            <div className="custom-web-section">
              <img
                src={require('../../images/customdev.png').default}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomService
