import React from "react";
import Cookies from "../Cookies/Cookies";
import Disclosure from "../Disclosure/Disclosure";
import "../Home/Home.scss";
import PersonalInfo from "../PersonalInfo/PersonalInfo";
import General from "../Privacy/Privacy";


function PrivacyPolicy() {
    window.scrollTo(0, 0);
  return (
    <div className="banner-overflow">
      <General/>
      <PersonalInfo/>
      <Disclosure/>
      <Cookies/>
    </div>
  );
}

export default PrivacyPolicy;
