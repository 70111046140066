import React from 'react'
import './CuServiceUi.scss'

function CuServiceUi() {
  return (
    <div className="custom-service-ui page-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="sub-title" data-aos="zoom-in" data-aos-once="true">
              UI/UX
            </h2>
            <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
              Development
            </h2>
            <p className="content" data-aos="zoom-in" data-aos-once="true">
              Axios works on a variety of UX UI and web design projects, from
              creating new products to reworking current mobile apps, websites,
              and corporate software. User research, product strategy, user
              interface and user experience design, usability testing,
              prototyping, web design and development are just a few of the
              services we offer to help you create a successful digital product.
            </p>
            <p className="content" data-aos="zoom-in" data-aos-once="true">
              As UI/UX development service provider, we work closely with our
              clients to define, design and develop transformative user
              experiences across all platforms and brand’s touchpoints.
            </p>
          </div>
          <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
            <div className="img-contener">
              <img
                src={require('../../images/UI-UX.png').default}
                alt="img"
                className="front-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CuServiceUi
