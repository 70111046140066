import React from "react";
import "./Cookies";

function Cookies() {
  return (
    <div className="privacy-section section-gap text-init">
      <div className="container">
        <h6 data-aos="zoom-in">Cookies</h6>
        <p className="content" data-aos="zoom-in">
          Cookies are a set of information that a particular website puts on
          your hard drive so that it remembers the site for later use. Our
          websites may use cookies for promotional campaigns, newsletter
          subscriptions, tracking our customers, push notifications, and keeping
          track of our customers’ preferences.
        </p>

        <p className="content" data-aos="zoom-in">
          Third-party advertisers might also create and access cookies in your
          browser, which will be subject to their privacy policy. In all cases,
           <p className="axios-font">Axios Softwork</p> will not be held responsible for acceptance of
          third-party cookies. Also, note that you can disable our cookie from
          the “security settings” section. However, the permanent disability of
          such cookies might reduce the performance of our site on your
          computer.
        </p>
        <h6 data-aos="zoom-in">Links to Other Websites</h6>
        <p className="content" data-aos="zoom-in">
          Our website might contain links to drive you towards another site with
          ease. However, once you are directed to a different side, we want you
          to know that we don’t have any control over the other website.
          Therefore, <p className="axios-font">Axios Softwork</p> will not be held responsible for your
          Personally Identifiable Information security whilst you are visiting
          other sites that are not governed by this privacy policy. We recommend
          you take caution while clicking specific links included in our
          content.
        </p>

        <h6 data-aos="zoom-in">CAN-SPAM Act</h6>
        <p className="content" data-aos="zoom-in">
          CAN-SPAM is an act passed in 2003. The act establishes certain sets of
          rules regarding commercial emails and gives recipients the right to
          tell businesses to stop emailing them otherwise severe penalties may
          occur.
        </p>

        <h6 data-aos="zoom-in">
          To be in accordance with CANSPAM, we agree to the following:
        </h6>
        <ol className="content" data-aos="zoom-in">
            <li>
            We do not use false or misleading header information.
            </li>
            <li>
            We do not use deceptive subject lines, and our subject lines reflect the content of our message.
            </li>
            <li>
            Honor opt-out/unsubscribe requests.
            </li>
            <li>
            We will notify our customers if any third-party marketing services are being used.
            </li>
            <li>
            We monitor all the work being done by other companies on our behalf.
            </li>
        </ol>

      </div>
    </div>
  );
}

export default Cookies;
