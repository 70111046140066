import React from "react";
import "./History.scss";

function History() {
  return (
    <div className="history-section section-gap">
      <div class="container">
        <h1 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Our Journey
        </h1>
        <h1
          className="main-title content"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Course of our voyage
        </h1>
        {/* <h1
          className="main-title content"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          work to perfection which ultimately help to boost your business.
        </h1> */}
        <div className="timeline-section">
          <div class="page">
            <div class="row">
              <ul id="timeline" class="timeline">
                <div class="arrowhead"></div>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      {/* <div class="timeline-title text-init "> */}
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">October 2018</h1>
                        <p className="content">
                         Officially launched Axios Softwork.
                        </p>
                      </div>
                      {/* </div> */}
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>

                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">November 2018</h1>
                        <p className="content">
                        For The Workout Station, a gym and fitness center in Balaju, the gym management system was released in its complete form.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">Feburary 2019</h1>
                        <p className="content">
                          School Management System (SMS) called
                          ‘NIVID’ was launched.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">April 2019</h1>
                        <p className="content">
                          Developed MVP of Najik.com (ecommerce platform company
                          which specializes in all kinds of B2C, B2B,
                          Merchandise & Services).
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>

                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">May 2019</h1>
                        <p className="content">
                        Launch NIVID and NIVID Guru on mobile platform.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">July 2019</h1>
                        <p className="content">
                          Partner with Luniva for the technical support.
                        
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">September 2019</h1>
                        <p className="content">
                        Developed Inventory and Billing System for ‘Aurora Traders Nepal’.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">November 2019</h1>
                        <p className="content">
                         Partner with Bolpatra Nepal for techical support
                        
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">December 2019</h1>
                        <p className="content">
                        Launch Luniva app on mobile platform.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">January 2020</h1>
                        <p className="content">
                         Developed an ecommerce website for ‘Aurora Traders Nepal’ which deals with Korean cosmetic product.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">March 2020</h1>
                        <p className="content">
                        Launch Bolpatra app on mobile platform.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                {/* .. */}
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">May 2020</h1>
                        <p className="content">
                         Developed a ecommerce website for Dabali Mart
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">July 2020</h1>
                        <p className="content">
                        Integrated distance education using NIVID in COVID pandamic
                        
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                {/* .. */}
                {/* .. */}
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">September 2020</h1>
                        <p className="content">
                        Introduce 30+ Cooperative Luniva clients to mobile and online banking services.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">November 2020</h1>
                        <p className="content">
                        Launched NIVID in 60+ schools.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                {/* .. */}
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink last-box">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">January 2021</h1>
                        <p className="content">
                          Dabali Store Platform was developed to create an
                          E-Commerce Website.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">Feburary 2021</h1>
                        <p className="content">
                          Developed Content Management System for Edfluent for professional proofreading services.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink last-box">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">April 2021</h1>
                        <p className="content">
                          Developed a platform for Virtual Eye Consultant.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">June 2021</h1>
                        <p className="content">
                          Developed a online Debating platform called as massdebaters.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink last-box">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">July 2021</h1>
                        <p className="content">
                        Provided IT personnel to Goddart Technical Solutions Pvt. Ltd. for supporting the product Avail Finance
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">August 2021</h1>
                        <p className="content">
                        Provided IT personnel to Innofin Techserve Pvt. Ltd. for supporting the product Lenden Club
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink last-box">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">October 2021</h1>
                        <p className="content">
                          Developed a Library Management System for Kathmandu University School of Medical Science
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-vert "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-blue">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">January 2022</h1>
                        <p className="content">
                            Developing a Tranaction Management System for Edfluent
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
                <li
                  class="timeline-inverted "
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div class="timeline-badge"> </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading bg-pink last-box">
                      <div className="card-section layer-bg-maroon">
                        <h1 className="title-content">Feburary 2022</h1>
                        <p className="content">
                          Developing a mobile based platform for connecting a surgeons and anaesthesiologists.
                        </p>
                      </div>
                    </div>

                    <div class="timeline-body"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
