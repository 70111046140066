import React from 'react'
import MetaConfigure from '../Helmet/Helmet'
import { FormikWrapper } from '../Stepper/FormikWrapper'
import HorizontalNonLinearStepper from '../Stepper/Stepper'

function Quotation() {
 
  return (
    <div className="stepper-section section-gap ">
      <MetaConfigure title="Request Quotation - Tell us your problems." />

      <div className="container">
        <FormikWrapper>
          <HorizontalNonLinearStepper />
        </FormikWrapper>
      </div>
    </div>
  )
}

export default Quotation
