import React, { useState } from 'react'
import './Strength.scss'

function Strength() {
  const [showMore2, setShowMore2] = useState(false)
  const [showMore1, setShowMore1] = useState(false)
  const [showMore3, setShowMore3] = useState(false)

  return (
    <div className="benefits-section section-gap">
      <div className="container">
        <h1 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Our Strength
        </h1>
        <h2
          className="main-title  content"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Axios Softwork is a team of highly energetic and passionate team
          members
        </h2>
        <div className="benefit-content">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div
                className="card-section layer-bg-maroon bg-hover-maroon"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <div className="benefit-col">
                  <div className="outer-img-card ">
                    <div className="b-1">
                      <img
                        src={require('../../images/aboutus/Frame1.png').default}
                        alt="icon"
                        className="icon-img"
                      />
                    </div>
                  </div>

                  <div className="benefit-sub-content">
                    <h2 className="sub-title">High Quality Code</h2>
                    <p
                      for="#btn-1"
                      className={`content mb-unset ${showMore2 ? '' : 'less'}`}
                    >
                      Our programmers are experts in writing high-quality code
                      that is simple, well-tested, well-documented, and
                      high-performing.
                    </p>
                    <button
                      type="button"
                      id="btn-1"
                      onClick={() => setShowMore2(!showMore2)}
                      className="see-btn"
                    >
                      {showMore2 ? 'Show less' : 'Show more'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                className="card-section layer-bg-maroon bg-hover-orange"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <div className="benefit-col">
                  <div className="outer-img-card ">
                    <div className="b-2">
                      <img
                        src={require('../../images/aboutus/Frame2.png').default}
                        alt="icon"
                        className="icon-img"
                      />
                    </div>
                  </div>

                  <div className="benefit-sub-content">
                    <h2 className="sub-title">Agile Approach</h2>
                    <p
                      for="btn-2"
                      className={`content mb-unset ${showMore1 ? '' : 'less'}`}
                    >
                      We follow the Agile methodology to provide high-quality
                      service to our customers within their budget and timeframe
                    </p>
                    <button
                      type="button"
                      id="btn-2"
                      onClick={() => setShowMore1(!showMore1)}
                      className="see-btn"
                    >
                      {showMore1 ? 'Show less' : 'Show more'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                className="card-section layer-bg-maroon bg-hover-green"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <div className="benefit-col">
                  <div className="outer-img-card ">
                    <div className="b-3">
                      <img
                        src={require('../../images/aboutus/Frame3.png').default}
                        alt="icon"
                        className="icon-img"
                      />
                    </div>
                  </div>

                  <div className="benefit-sub-content">
                    <h2 className="sub-title">Full-stack Teams</h2>
                    <p
                      for="btn-3"
                      className={`content mb-unset ${showMore3 ? '' : 'less'}`}
                    >
                      We have a self-contained, autonomous full stack team who
                      collectively possess all of the abilities required to
                      address the challenge assigned to them.
                    </p>
                    <button
                      type="button"
                      id="btn-3"
                      onClick={() => setShowMore3(!showMore3)}
                      className="see-btn"
                    >
                      {showMore3 ? 'Show less' : 'Show more'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                className="card-section layer-bg-maroon bg-hover-purple"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <div className="benefit-col">
                  <div className="outer-img-card ">
                    <div className=" b-4">
                      <img
                        src={require('../../images/aboutus/Frame4.png').default}
                        alt="icon"
                        className="icon-img"
                      />
                    </div>
                  </div>

                  <div className="benefit-sub-content">
                    <h2 className="sub-title">Quick Bug Fixes</h2>
                    <p className="content">
                      Our team is expert in fixing any types of bugs if found
                      any in the software
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Strength
