import React from "react";
import "./SubBanner.scss";
function SubBanner() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h2 className="main-title">About Axios</h2>
              <p className="content">
              Axios Softwork Pvt. Ltd., a start-up business founded in 2018, is a leading, highly inventive software firm that was created to offer commercial organizations cutting-edge intelligent technical solutions and services. We are a team of professionals who uphold the highest standards of performance and ultimately help your business flourish.
              </p>
              {/* <p className="content">
                We provide you with an array of digital services, that too with
                guaranteed quality and satisfaction.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubBanner;
