import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./TabPanel.scss";

function TabPanels() {
  return (
    <div>
      <Tabs>
        <TabPanel>
          <div className="tab-name">
            <h2 className="tab-content">VP of Engineering</h2>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab-name">
            <h2 className="tab-content">Head of Technology</h2>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab-name">
            <h2 className="tab-content">Senior Marketer</h2>
          </div>
        </TabPanel>
        <TabList className="tab-component">
          <Tab>
            <div className="tab-title">
              <div className="tab-img-div">
                <img
                  src={require("../../images/ss.jpeg").default}
                  alt="images"
                  className="tab-img"
                />
              </div>
              <div>
                <h5 className="name">Pierre Hackett</h5>
                <p className="tab-main">Principal</p>
              </div>
            </div>
          </Tab>

          <Tab>
            <div className="tab-title">
              <div className="tab-img-div">
                <img
                  src={require("../../images/ss.jpeg").default}
                  alt="images"
                  className="tab-img"
                />
              </div>
              <div>
                <h5 className="name">Natalia Sanz</h5>
                <p className="tab-main">Head Of Technology</p>
              </div>
            </div>
          </Tab>

          <Tab>
            <div className="tab-title">
              <div className="tab-img-div">
                <img
                  src={require("../../images/ss.jpeg").default}
                  alt="images"
                  className="tab-img"
                />
              </div>
              <div>
                <h5 className="name">Ece Akman</h5>
                <p className="tab-main">Senior Marketer</p>
              </div>
            </div>
          </Tab>
        </TabList>
      </Tabs>
    </div>
  );
}
export default TabPanels;
