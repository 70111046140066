import React from 'react'
// import Particles from 'react-particles-js'
import './Particles.scss'
import { tsParticles } from "tsparticles-engine";
import BannerPart from './Particles';

class TsBannerPart extends React.Component {
  render() {
    return (
      <div className="particle-section">
       <BannerPart/>
      </div>
    )
  }
}

export default TsBannerPart