import React from "react";

function Axios() {
  return (
    <div className="QAns-section section-gap text-init">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Axios and Agile
        </h2>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          In addition to embracing Agile in our own development work, Axios has
          trained Agilists that excel at teaching our clients about Agile
          process solutions at the program and delivery team levels. We help
          customers adopt an Agile mindset as well as particular Agile
          methodologies and frameworks. We also help customers structure their
          Agile development processes to ensure efficiency and scalability, as
          well as provide them with precise metrics to track the success of
          their Agile development.
        </p>
      </div>
    </div>
  );
}

export default Axios;
