import Axios from "./AxiosInstance";

export const ContactUsPage = (data) => {
  return Axios.post("api/contactus", data);
};
export const CarrerPage = (data) => {

  return Axios.post("api/axios/carrer", data);
};
export const QuotationPage = (data) => {

  return Axios.post("api/axios/quotation", data);
};

export const UptoDate = (data) => {
  return Axios.post("/stay_up_to_date", data);
};
