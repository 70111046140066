import React from "react";
import "./CuServiceVP.scss";

function CuServiceVP() {
  return (
    <div className="custom-service-vp page-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2 className="sub-title" data-aos="zoom-in" data-aos-once="true">
              Minimum Viable
            </h2>
            <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
              Product Development
            </h2>
            <p className="content" data-aos="zoom-in" data-aos-once="true">
              Reduce time-to-market with our fast & agile development process in
              just 4-8 weeks. The result is a functional version of your product
              with all core features ready to test with users.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CuServiceVP;
