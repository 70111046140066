import React from 'react'
import './RequestQuotation.scss'
import { Link } from 'react-router-dom'

function RequestQuotation() {
  return (
    <div className="request-section section-gap">
      <div className="container">
        <div
          className="request-content"
          data-aos="zoom-in"
          data-aos-once="true"
        >
            <div className="width-50">
              <h2 className="main-title">Have a project in mind?</h2>
            </div>
            <div className="width-mid">
              <img className='arrow' src={require('../../images/curve.png').default} alt="img" />
            </div>
            <div className="width-25">
              <div className="request-request">
                <img
                  src={require('../../images/shine.png').default}
                  alt="img"
                />
                <div
                  onClick={() => {
                    window.scrollTo(0, 0)
                  }}
                  className="btn-section"
                >
                  <Link to="/quotation">
                    <button className="main-btn">Request Quotation</button>
                  </Link>
                </div>
                <img
                  className="shine"
                  src={require('../../images/shine.png').default}
                  alt="img"
                />
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default RequestQuotation
