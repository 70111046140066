import React from 'react'
import './QualityBox.scss'

function QualityBox() {
  window.scrollTo(0, 0)
  return (
    <div className="quality-section section-gap">
      <div className="container">
        <h2 className="main-title mb-15" data-aos="zoom-in">
          Our Services
        </h2>
        <p className="benefits-content content">
          Axios provides third-party apps and solutions with independent QA
          services. We cover every aspect of your project, from functionality to
          security to deliver high-quality software.
        </p>

        <div className="row">
          <div className="col-md-12 col-lg-6  col-xl-6 " data-aos="zoom-in">
            <div className="card-section  bg-lightpink lightpink-bg ">
              <div className="outer-img-card">
                <div className="img-card ">
                  <img
                    className="logo-img"
                    src={require('../../images/quality/Frame.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Security Testing</h2>
                <p className="content">
                  Security is a specialty of our QA engineers. We'll conduct a
                  thorough analysis to identify system flaws, make critical
                  recommendations, and rapidly improve the security of your
                  application.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6 col-xl-6" data-aos="zoom-in">
            <div className="card-section bg-lightblue lightblue-bg">
              <div className="outer-img-card">
                <div className="img-card  ">
                  <img
                    className="logo-img"
                    src={require('../../images/quality/Frame (1).png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Usability Testing</h2>
                <p className="content">
                  Axios thinks about your users and exactly how they'll interact
                  with your application. Our exhaustive analysis identifies
                  potential errors, confusing designs, and performance flaw — as
                  well as the solutions these issues will require.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6" data-aos="zoom-in">
            <div className="card-section  bg-lightgreen lightgreen-bg">
              <div className="outer-img-card">
                <div className="img-card ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame (2).png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Performance Testing</h2>
                <p className="content">
                  Axios specialists will assess how much use your app can handle
                  and, if anything, what is holding it down. When it's time to
                  scale up, we'll continue to test to make sure nothing goes
                  wrong.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6" data-aos="zoom-in">
            <div className="card-section  bg-lightpurple lightpurple-bg  ">
              <div className="outer-img-card">
                <div className="img-card  ">
                  <img
                    className="logo-img"
                    src={require('../../images/quality/Frame (2).png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Compatibility Testing</h2>
                <p className="content">
                  Our QA experts will thoroughly evaluate your application's
                  compatibility with a variety of browsers, databases, display
                  resolutions, and devices. We'll look at how your program is
                  affected by hardware and server infrastructure.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QualityBox
