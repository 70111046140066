import React from "react";
import "./DevEnvironment.scss";
import Slider from "react-slick";

function DevEnvironment() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="dev-environment-section section-gap">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Development Enviroment
        </h2>
        <p
          className="content content-space"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Axios's solutions are builts on modern and scalable architectures with
          robust, industry-standard technologies.
        </p>
        <div className="container">
          <Slider {...settings}>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/figma-r-bg.png").default}
                  className="img-color height-66"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/mongo.png").default}
                  className="img-color height-66"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/postgres.png").default}
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/mySQL.png").default}
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/ms_SQL.png").default}
                  className="img-color height-66"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/node-r-bg.png").default}
                  alt="img"
                  className="img-color"
                />
              </div>
            </div>

            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/python-r-bg.png").default}
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/react-r-bg.png").default}
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={
                    require("../../images/DElogos/ruby-on-rails-r-bg.png")
                      .default
                  }
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>

            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/jsr-bg.png").default}
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={
                    require("../../images/DElogos/html-r-bg (1).png").default
                  }
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/css-r-bg (1).png").default}
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>

            <div className="box">
              <div className="slide-box">
                <img
                  src={
                    require("../../images/DElogos/dart-removebg.png").default
                  }
                  className="img-color"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/kotlin-r-bg.png").default}
                  className="img-color resize-img"
                  alt="img"
                />
              </div>
            </div>
            <div className="box">
              <div className="slide-box">
                <img
                  src={require("../../images/DElogos/flutter-r-bg.png").default}
                  className="img-color resize-img"
                  alt="img"
                />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default DevEnvironment;
