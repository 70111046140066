import React from "react";
import "./CentricProcess.scss";

function CentricProcess() {
  return (
    <div className="centricProcess-section section-gap">
      <div className="container">
        <div className="mgb-30">
          <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
            A user-centric process
          </h2>
          <p className="sub-title" data-aos="zoom-in" data-aos-once="true">
            What are the outcomes and deliverables from our in-depths user
            discovery?
          </p>
        </div>

        <div
          className="centricProcess-content d-flex "
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <i class="bi bi-play-fill"></i>
          <div>
            <h2 className="sub-title">Site Architecture (Site Map)</h2>
            <p className="content">
              The framework that organizes and distributes the material on your
              website is referred to as site architecture. It covers the page
              structure where consumers discover information as well as
              technological factors that allow search engine bots to explore
              your sites.
            </p>
          </div>
        </div>

        <div
          className="centricProcess-content d-flex"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <i class="bi bi-play-fill"></i>
          <div>
            <h2 className="sub-title">Page Wireframes</h2>
            <p className="content">
              Loose wireframes are used to outline key pages of your website
              (persona pages, services, goods, solutions. These rough drawings
              offer suggestions for page structure, space allocation, and
              content priority. Wireframes can also be used as templates for
              copywriting.Wireframes also serve as templates to help guide your
              copywriting.
            </p>
          </div>
        </div>
        <div
          className="centricProcess-content d-flex"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          <i class="bi bi-play-fill"></i>
          <div>
            <h2 className="sub-title">Call-to-Action (CTA) Strategy</h2>
            <p className="content">
              You must provide users with options to contact you in order to
              engage them and create leads. Whether it's a download, a trial, a
              consultation, or just a chance to talk to a real person, your
              website requires an appealing offer that makes the visitor want to
              provide their personal information. We'll show you how to
              carefully create CTAs and put them in the right places on your
              website.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CentricProcess;
