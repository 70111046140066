import React from "react";

function OurAgile() {
  return (
    <div className="QAns-section section-gap text-init">
      <div className="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          Our Agile Software Development Services
        </h2>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          Axios' Agile methodology enables us to create high-quality software
          that meets stakeholders' shifting demands more cost-effectively and
          quickly than ever before. We also assist companies in developing Agile
          skills and implementing Agile principles and techniques.
        </p>
      </div>
    </div>
  );
}

export default OurAgile;
