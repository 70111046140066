import React from "react";
import SubBannerVP from "../SubBannerVP/SubBannerVP";
import CuServiceVP from "../CuServiceVP/CuServiceVP";
import CentricProcess from "../CentricProcess/CentricProcess";
import QAnswer from "../QAnswer/QAnswer";
import "../Home/Home.scss";
import MetaConfigure from "../Helmet/Helmet";

function ViableProduct() {
  return (
    <div className="banner-overflow">
      <MetaConfigure title="ViableProducts-AxiosSoftworks" />

      <SubBannerVP />
      <CuServiceVP />
      <QAnswer />
      <CentricProcess />
    </div>
  );
}

export default ViableProduct;
