import React from "react";

import "./MissionVision.scss";

function VisionMission() {
  return (
    <div className="missionVision-section section-gap">
      <div className="container">
        <h1
          className="content-text text-init"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Our Mission and Vision
        </h1>

        <div className="missionV-content">
          <div className="row">
            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <div className="missionV-col">
                <div className="ben-icon b-1">
                  <img
                    src={require("../../images/mission.svg").default}
                    alt="icon"
                  />
                </div>
                <div className="missionV-sub-content">
                  <h2 className="sub-title">Mission</h2>
                  <p className="content">
                    To shape ideas into reality by creating a roadmap using
                    leading-technology assuring quality.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <div className="missionV-col">
                <div className="ben-icon b-2">
                  <img
                    src={require("../../images/vision.svg").default}
                    alt="icon"
                  />
                </div>
                <div className="missionV-sub-content">
                  <h2 className="sub-title">Vision</h2>
                  <p className="content">
                    To become the Hub for best-of-breed technical innovation and
                    solution.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-once="true"
            >
              <div className="missionV-col">
                <div className="ben-icon b-3">
                  <img
                    src={require("../../images/obj.svg").default}
                    alt="icon"
                  />
                </div>
                <div className="missionV-sub-content">
                  <h2 className="sub-title">Core Values</h2>
                  <p className="content">
                    Customer centric innovation, quality and consistency in
                    service. Team collaboration and effort.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VisionMission;
