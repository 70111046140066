import React from 'react'
import './CuServiceCloud.scss'

function CuServiceCloud() {
  return (
    <div className="custom-service-cloud page-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="sub-title" data-aos="zoom-in" data-aos-once="true">
              Cloud
            </h2>
            <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
              Services
            </h2>
            <p className="content" data-aos="zoom-in" data-aos-once="true">
              Architecture and automation are essential components of every
              cloud service supplied, and are critical to any company's cloud
              deployment. Axios' Cloud Solutions provide an uniform user
              interface and API for both public and private cloud services.
            </p>
          </div>
          <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
            <div className="img-contener">
              <img
                src={require('../../images/Cloud.png').default}
                alt="img"
                className="front-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CuServiceCloud
