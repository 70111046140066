import React from "react";

function PersonalInfo() {
  return (
    <div className="privacy-section section-gap text-init">
      <div className="container">
        <h6 data-aos="zoom-in">Use of Personal Information</h6>
        <p className="content" data-aos="zoom-in">
          Axios uses Personally Identifiable Information for multiple causes.
          Some reasons might resonate with customers/visitors, while others
          might be used for the benefit of the company and improvement of
          customer services. We might use the provided information in the
          following ways.
        </p>
        <ol className="content" data-aos="zoom-in">
            <li>
            To provide access to privileged areas of our website, send newsletters, and recommend quintessential services.
            </li>
            <li>
            To enhance our customer services and fix the areas of the site which require improvement.
            </li>
            <li>
            To improve our products and services.
            </li>
            <li>
            To follow up with customers/visitors after correspondence (live chat, email, or phone inquiries)
            </li>
            <li>
            To occasionally carry out market research and send you details of services and offers that we think 
            may be of interest to you.
            </li>
        </ol>

        <p className="content" data-aos="zoom-in"></p>
      </div>
    </div>
  );
}

export default PersonalInfo;
