import React from "react";
import "./AppService.scss";
function AppService() {
  return (
    <div className="app-service page-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="sub-title" data-aos="zoom-in" data-aos-once="true">
              Mobile App
            </h2>
            <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
              Development
            </h2>
            <p className="content" data-aos="zoom-in" data-aos-once="true">
              Any modern firm relies heavily on mobile software. Axios has a lot
              of expertise with diverse technology stacks and building creative
              mobile apps. We create the best mobile apps for our clients; our
              solutions are dependable and strong, and we always employ the most
              up-to-date and effective UI/UX.
            </p>
          </div>
          <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
            <div className="img-contener">
            <img
              src={require("../../images/appdev/mobile-dev.png").default}
              alt="img"
              className="front-img"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppService;
