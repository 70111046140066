import React, { Component } from 'react'
import './seniorQa.scss'
import { Link } from 'react-router-dom'

export default class Qa extends Component {
  render() {
    const { jobDescription } = this.props
    const { handleBack } = this.props
    window.scrollTo(0, 0)

    return (
      <div className="box" data-status="true">
        <div>
          <div className="container">
            <div className="page">
              <div className="scroll-bar">
                <div className="top-section">
                  <p className="content gray">{jobDescription?.job_type}</p>
                  <p className=" content red">Description</p>
                </div>
                <div className="second-section">
                  <p className="content sm-content">
                    {jobDescription?.description}
                  </p>
                  <p className="content red">REQUIREMENTS</p>
                </div>
                <div className="third-section">
                  <p className="content sm-content">
                    {jobDescription?.requirements}
                  </p>
                </div>
                <div className="footer">
                <div className="btn-section">
                  <button
                    onClick={handleBack}
                    className="white-btn"
                  >
                    <i className="bi bi-arrow-left"></i> Go Back
                  </button>
                  <Link to="/quot">
                    <button className="main-btn">
                      Apply For this Position
                    </button>
                  </Link>
                </div>
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    )
  }
}
