import React from "react";
import BenefitsOfCustomer from "../BenefitsOfCustomer/BenefitsOfCustomer";
import CustomService from "../CustomService/CustomService";
import DevEnvironment from "../DevEnvironment/DevEnvironment";
import DevProcess from "../DevProcess/DevProcess";
import SubBannerWD from "../SubBannerWD/SubBannerWD";
import "../Home/Home.scss";
import MetaConfigure from "../Helmet/Helmet";

function WebDevelopment() {
  return (
    <div className="banner-overflow">
      <MetaConfigure title="WebDevelopment-AxiosSoftworks" />

      <SubBannerWD />
      <CustomService />
      <BenefitsOfCustomer />
      <DevEnvironment />
      <DevProcess />
    </div>
  );
}

export default WebDevelopment;
