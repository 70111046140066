import React from "react";
import "./SubBannerVP.scss";

function SubBannerVP() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
      <div className="top-section">
          <div className="top-text">
              <h2 className="main-title">  Minimum Viable Product Development</h2>
              <p className="content">
              Reduce time-to-market with our fast & agile development process in 
              just 4-8 weeks.
              </p>
              
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubBannerVP;
