import React from "react";
import Footprints from "../Footprints/Footprints";
import History from "../History/History";
import VisionMision from "../MissionVision/MissionVision";
import Strength from "../Strength/Strength";
import SubBanner from "../SubBanner/SubBanner";
import "../Home/Home.scss";
import MetaConfigure from "../Helmet/Helmet";

function Aboutus() {
  window.scrollTo(0, 0);
  return (
    <div className="banner-overflow">
      <MetaConfigure title="About us - What are we?" />

      <SubBanner />
      <VisionMision />
      <Footprints />
      <Strength />
      <History />
    </div>
  );
}

export default Aboutus;
