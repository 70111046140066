import React from 'react'
import './SubBannerQuality.scss'
function SubBannerQuality() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
        <div className="top-section">
          <div className="top-text">
            <h2 className="main-title">Quality Assurance Services</h2>
            <p className="content">
              Focused on solving complex problems on Web with clear and
              effective solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubBannerQuality
