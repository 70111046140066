import React, { useContext } from "react";
import "./Footer.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { UptoDate } from "../Api/PostApi";
import { GlobalContext } from "../Context/GlobalContext";
function Footer() {
  const { successMessage, errorMesssage } = useContext(GlobalContext);

  return (
    <div className="footer-section section-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-4">
            <div className="d-flex">
              <img
                className="logo-img"
                src={require("../../images/logo.png").default}
                alt="img"
              />
              <p className="content mg-auto mb-30">Axios Softwork</p>
            </div>
            <div className="">
              <p className="content">Copyright @ 2021 Axios Softwork</p>
              <p className="content">All rights reserved</p>
            </div>

            <div className="d-flex">
              <div className="img-card">
                <a href="https://www.linkedin.com/company/axios-softworks/mycompany/">
                  <img
                    src={require("../../images/logos/Path.png").default}
                    alt="img"
                    className="logo-path"
                  />
                </a>
              </div>

              <div className="img-card">
                <a href="https://www.youtube.com/channel/UCEdAiBF07VibSGD0qAWypPg">
                  <img
                    src={require("../../images/youtube.png").default}
                    alt="img"
                  />
                </a>
              </div>

              <div className="img-card">
                <a href="https://www.facebook.com/axiossoftwork/">
                  <img
                    src={require("../../images/facebook.png").default}
                    alt="img"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-sm-4 col-6 ">
            <h2 className="sub-title mb-10">Company</h2>
            <div className="list-space">
              <li>
                <Link to="/introduction" className="content hg">
                  About us
                </Link>
              </li>

              <li>
                <Link to="/contact-us" className="content hg">
                  Contact us
                </Link>
              </li>
            </div>
          </div>

          <div className="col-lg-3 col-sm-4 col-6 ">
            <h2 className="sub-title mb-10">Support</h2>
            <div className="list-space">
              <li>
                <Link to="/" className="content hg">
                  Terms of services
                </Link>
              </li>
              <li>
                <Link to="/privacypolicy" className="content hg">
                  Privacy policy
                </Link>
              </li>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-12">
            <h2 className="sub-title mb-10">Stay up to date</h2>
            <div className="hold">
              <Formik
                initialValues={{
                  Email: "",
                }}
                validationSchema={Yup.object({
                  Email: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  var formData = new FormData();
                  formData.append("Email", values.Email);

                  UptoDate(formData).then(({ data }) => {
                    if ((data.status = 200)) {
                      successMessage("Sent Succesfully");
                      resetForm({
                        values: {
                          Email: "",
                        },
                      });
                    } else {
                      errorMesssage("something went wrong");
                      resetForm({
                        values: {
                          Email: "",
                        },
                      });
                    }
                  });
                  resetForm({
                    values: {
                      Email: "",
                    },
                  });
                  setSubmitting(false);
                }}
              >
                <Form>
                  <button type="submit" className="logo">
                    <img
                      className="img-logo"
                      src={require("../../images/send.png").default}
                      alt="img"
                    />
                  </button>
                  <Field
                    name="Email"
                    type="email"
                    className="form-control input-field"
                    placeholder="Your email address"
                  />
                  <span className="error">
                    <ErrorMessage className="error" name="Email" />
                  </span>
                  <div>
                    <ToastContainer />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
