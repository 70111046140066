import React from "react";
import SubBanner from "../SubBanner/SubBanner";
import WorkPage from "../WorkPage/WorkPage";
import MetaConfigure from "../Helmet/Helmet";

function OurWorks() {
  window.scrollTo(0, 0);
  return (
    <div id="#allworks">
      <MetaConfigure title="All Projects & Case Studies | Axios Softwork| Web, Mobile, E-commerce" />

      <SubBanner />
      <WorkPage />
    </div>
  );
}

export default OurWorks;
