import React from 'react'
import './JoinTeam.scss'

function JoinTeam() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <h2 className="main-title"> Join Our Team</h2>
              <p className="content">
                Every day, the software you create shapes the world we live in.
                Leave
                <p className="content mg-bottom-0">
                  your mark by turning ideas into software that make people’s
                  lives
                </p>
                <p className="content mg-top-0">more enjoyable and productive.</p>
              </p>
              <div className='mid-btn-section'>
                <a href="#jobpositions">
                  <button type="button" className="main-btn">
                    View Job Listing
                  </button>
                </a>
                <a href="/best-offer">
                  <button className="main-btn white">Send CV</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinTeam
