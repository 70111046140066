import React from "react";
import "./Timeline.scss";
function Timeline() {
  return (
    <div className="timeline-content">
      <div class="container">
        <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
          We Offer A Range of Cloud Based Services
        </h2>
        <p className="content" data-aos="zoom-in" data-aos-once="true">
          Together with offering a complete suite of cloud solutions, we also
          offer expert support in data center solutions, networking and
          converged communication. With our unique operations model, you enjoy
          versatile, pay-as-you-go, multi-tenant and on-demand cloud solutions.
        </p>

        <div className="timeline-content-area">
          <div class="row">
            <ul id="timeline" class="timeline">
              <div class="arrowhead"></div>

              <li class="timeline-inverted ">
                <div class="timeline-badge"> </div>
                <div class="timeline-panel">
                  <div class="timeline-title text-init">
                    <h1
                      className="sub-title"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Cloud storage
                    </h1>
                    <p
                      className="content"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      We may be able to offer you customized storage packages,
                      regardless of how small or large your storage needs are.
                      We have long-term storage, intensive applications and
                      databases, and enterprise SSD Flash storage tiers.
                    </p>
                  </div>
                </div>
              </li>

              <li class="timeline-inverted ">
                <div class="timeline-badge"> </div>
                <div class="timeline-panel">
                  <div class="timeline-title text-init">
                    <h1
                      className="sub-title"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Back up & Replication
                    </h1>
                    <p
                      className="content"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Axios provides Backup and Replication solutions using most
                      promising technologies. We use cloud backup to keep files
                      and data accessible in the case of a system outage,
                      interruption, or natural catastrophe.
                    </p>
                  </div>
                </div>
              </li>

              <li class="timeline-inverted ">
                <div class="timeline-badge"> </div>
                <div class="timeline-panel">
                  <div class="timeline-title text-init">
                    <h1
                      className="sub-title"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Security
                    </h1>
                    <p
                      className="content"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Our multi-layered security strategy ensures that your
                      sensitive information is kept safe and secure. Our
                      dependable cloud services assist customers in being more
                      responsive, as well as increasing dependability and
                      security. To ensure faultless server uptime, our data
                      centers are built on a fully redundant architecture with
                      automated failover.
                    </p>
                  </div>
                </div>
              </li>

              <li class="timeline-inverted ">
                <div class="timeline-badge"> </div>
                <div class="timeline-panel">
                  <div class="timeline-title text-init">
                    <h1
                      className="sub-title"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Load Balancing
                    </h1>
                    <p
                      className="content"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Load balancers are used to achieve remarkable application
                      scalability and redundancy. Load balanced Cloud Servers
                      eliminate bottlenecks during peak traffic times and thus
                      help websites achieve maximum uptime.
                    </p>
                  </div>
                </div>
              </li>

              <li class="timeline-inverted ">
                <div class="timeline-badge"> </div>
                <div class="timeline-panel">
                  <div class="timeline-title text-init">
                    <h1
                      className="sub-title"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      Remote Desktop
                    </h1>
                    <p
                      className="content"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      We let you access your desktop and apps from any corner of
                      the world. Our Cloud Solutions make your desktop and
                      applications available online.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;
