import React from "react";
import TeamDetails from "../../TeamDetails/TeamDetails";
import MetaConfigure from "../Helmet/Helmet";
import SubBanner from "../SubBanner/SubBanner";

function Team() {
  return (
    <div className="team-section ">
      <MetaConfigure title="Our Team - The gem of our organization" />

      <SubBanner />
      <TeamDetails />
    </div>
  );
}

export default Team;
