import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "./Footprints.scss";

const Footprints = () => {
  return (
    <div className="footprint-section section-gap">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex">
              <div
                className="card-section card-top layer-bg-maroon"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <div className="center-div">
                  <h2 className="main-title title-color">
                    {" "}
                    <CountUp end={40} duration={1.5} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>{" "}
                    +
                  </h2>
                  <p className="content">Project Delivered</p>
                </div>
              </div>
              <div
                className="card-section layer-bg-orange"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <div className="center-div">
                  <h2 className="main-title title-color">
                    {" "}
                    <CountUp end={50} duration={1.5} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>{" "}
                    +
                  </h2>
                  <p className="content">Satisfied Clients</p>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div
                className="card-section card-top layer-bg-pink"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <div className="center-div">
                  <h2 className="main-title title-color">
                    {" "}
                    <CountUp end={30} duration={1.5} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>{" "}
                    +
                  </h2>
                  <p className="content">Passionate Expertise</p>
                </div>
              </div>
              <div
                className="card-section layer-bg-skyblue"
                data-aos="zoom-in"
                data-aos-once="true"
              >
                <div className="center-div">
                  <h2 className="main-title title-color">
                    <CountUp end={3} duration={1.5} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h2>
                  <p className="content">Year’s experience</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
            <div className="footprints-content  text-init ">
              <div>
                <h2 className="main-title ">Our Footprints</h2>
                <p className="content">
                  Axios is delighted to be a trusted by number of clients in
                  Japan, India, USA, UK, and Australia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footprints;
