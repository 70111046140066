import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GlobalContext = createContext({
  successMessage: (str) => {},
  errorMessage: (str) => {},
  userId: "",
  userName: "",
  setUserId: (str) => {},
  setUserName: (str) => {},
});

const GlobalProvider = () => {
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");

  const successMessage = (str) => {
    toast.success(str);
  };
  const errorMessage = (str) => {
    toast.error(str);
  };
  return (
    <GlobalContext.Provider
      value={{
        successMessage,
        errorMessage,
        userId,
        userName,
        setUserId,
        setUserName,
      }}
    ></GlobalContext.Provider>
  );
};

const GlobalConsumer = GlobalContext.Consumer;
export { GlobalContext, GlobalProvider, GlobalConsumer };
