import React from "react";
import CuServiceQuality from "../CuServiceQuality/CuServiceQuality";
import SubBannerQuality from "../SubBannerQuality/SubBannerQuality";
import "../Home/Home.scss";
import QualityBox from "../QualityBox/QualityBox";
import MetaConfigure from "../Helmet/Helmet";

function AssuranceService() {
  return (
    <div className="banner-overflow">
      <MetaConfigure title="AssuranceService-AxiosSoftworks" />

      <SubBannerQuality />
      <CuServiceQuality />
      <QualityBox />
    </div>
  );
}

export default AssuranceService;
