import React from 'react'
import './WeServe.scss'

function WeServe() {
  window.scrollTo(0, 0)
  return (
    <div id="weserve" className="weserve-section section-gap">
      <div className="container">
        <h2
          className="main-title"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Who we Serve ?
        </h2>

        <div className="row">
          <div
            className="col-md-12 col-lg-6  col-xl-6 "
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="side-card bg-yelloww lightyellow-bg">
              <div className="outer-img-card ">
                <div className="img-card bg-yellow lightyellow-bg">
                  <img
                    className=""
                    src={require('../../images/serve a.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Educational Institutes</h2>
                <p className="content">
                  Serving 100 + educational institutes with the ERP software for
                  the management of School activities, commuication, Operation,
                  Mobile application for parents, teachers, administration etc.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="side-card bg-pinky lightpink-bg  ">
              <div className="outer-img-card ">
                <div className="img-card bg-pink lightpink-bg ">
                  <img
                    className=""
                    src={require('../../images/serve b.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Business Solution</h2>
                <p className="content">
                  Axios offers a solution to businesses for better client
                  service and consumer satisfaction. We deliver exactly what you
                  need to help your business succeed quicker.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="side-card bg-lightpurple lightpurple-bg">
              <div className="outer-img-card ">
                <div className="img-card bg-purple lightpurple-bg">
                  <img
                    className=""
                    src={require('../../images/serve c.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">E-commerce</h2>
                <p className="content">
                  Providing ecommerce service for 20+ outlets. Business
                  organization have been trusting us in the transaction of their
                  data and information by using our product.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="side-card bg-lightblue lightblue-bg ">
              <div className="outer-img-card ">
                <div className="img-card bg-blue lightblue-bg ">
                  <img
                    className=""
                    src={require('../../images/serve d.png').default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="weserve-content">
                <h2 className="sub-title ">Financial Organization</h2>
                <p className="content">
                  70+ financial institutions have trusted us to enhance their
                  services. We offer web and mobile application with multiple
                  features having access to varying aspects.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeServe
