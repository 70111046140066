import React from "react";
import Banner from "../Banner/Banner";
import ImageGallaryComponent from "../Feedback/Feedback";
import Footprints from "../Footprints/Footprints";
import Platform from "../Platforms/Platform";
import RecentWorks from "../RecentWorks/RecentWorks";
import WeServe from "../WeServe/WeServe";
import WhyAxios from "../WhyAxios/WhyAxios";
import "./Home.scss";

function Home() {
  return (
    <div className="banner-overflow">
      <Banner />
      <WeServe />
      <WhyAxios />
      <Footprints />
      <Platform />
      <RecentWorks />

      <ImageGallaryComponent />
    </div>
  );
}

export default Home;
