import React from "react";
import "./CuServiceQuality.scss";

function CuServiceQuality() {
  return (
    <div className="custom-service-quality page-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="sub-title" data-aos="zoom-in" data-aos-once="true">
              Quality Assurance
            </h2>
            <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
              Services
            </h2>
            <p className="content" data-aos="zoom-in" data-aos-once="true">
              Axios develops operational controls to ensure that the results
              match the desired outcomes. Customer service operations are
              designed to keep customers satisfied while protecting the
              organization that helps customers bring software products to
              market with superior quality.
            </p>
          </div>
          <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
            <div className="img-contener">
            <img
              src={require("../../images/Quality.png").default}
              alt="img"
              className="front-img"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CuServiceQuality;
