import React from 'react'
import './StepperThird.scss'
import { Field, ErrorMessage } from 'formik'
import 'react-toastify/dist/ReactToastify.css'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'purple',
    top: '-3px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'purple',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E5E5E5',
    },
    '&:hover fieldset': {
      borderColor: '#E5E5E5',
    },
    '&.Mui-focused fieldset': {
      border: '0.5px solid black',
    },
  },
})

const numberInputOnWheelPreventChange = (e) => {
  e.target.blur()
  // Prevent the page/container scrolling
  e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
  }, 0)
}

function StepperThird() {
  return (
    <div className="stepperthird-section section-gap">
      <div className="container">
        <div className="contents">
          <h2 className="main-title ">Let us know, how can we reach you</h2>

          <div className="row">
            <div className="col-md-12">
              <div className="material-textfields">
                <Field
                  className="text-area"
                  type="text"
                  name="name"
                  render={({ field }) => (
                    <CssTextField
                      label="Name"
                      name="name"
                      id="custom-css-outlined-input-companyName"
                      {...field}
                    />
                  )}
                />
                {/* <label>Name</label> */}
              </div>
              <span className="error">
                <ErrorMessage name="name" />
              </span>
            </div>
            <div className="col-md-6">
              <div className="material-textfields">
                <Field
                  className="text-area"
                  name="company_name"
                  type="text"
                  render={({ field }) => (
                    <CssTextField
                      label="Company Name"
                      name="company_name"
                      id="custom-css-outlined-input-companyName"
                      {...field}
                    />
                  )}
                />
                {/* <label>Company Name</label> */}
              </div>
              <span className="error">
                <ErrorMessage name="company_name" />
              </span>
            </div>
            <div className="col-md-6">
              <div className="material-textfields">
                <Field
                  className="text-area"
                  type="number"
                  name="phone"
                  onWheel={numberInputOnWheelPreventChange}
                  pattern="^[0-9]*$"
                  maxLength="10"
                  render={({ field }) => (
                    <CssTextField
                      label="Phone Number"
                      name="phone"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      id="custom-css-outlined-input-phone"
                      {...field}
                    />
                  )}
                />
                {/* <label>Phone</label> */}
              </div>
              <span className="error">
                <ErrorMessage name="phone" />
              </span>
            </div>
            <div className="col-md-12">
              <div className="material-textfields">
                <Field
                  name="email"
                  className="text-area"
                  type="email"
                  render={({ field }) => (
                    <CssTextField
                      label="Email"
                      name="email"
                      id="custom-css-outlined-input-email"
                      {...field}
                    />
                  )}
                />
                {/* <label>Email</label> */}
              </div>
              <span className="error">
                <ErrorMessage name="email" />
              </span>
            </div>
            <div className="col-md-12">
              <div className="material-textfields">
                <Field
                  name="description"
                  id="outlined-textarea"
                  label="Message"
                  render={({ field }) => (
                    <CssTextField
                      label="Message"
                      name="description"
                      id="custom-css-outlined-input"
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                  variant="outlined"
                  margin="dense"
                />
              </div>
              <span className="error">
                <ErrorMessage name="description" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default StepperThird
