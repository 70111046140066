import React from "react";
import "./SubBannerCloud.scss";
function SubBannerCloud() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
      <div className="top-section">
          <div className="top-text">
              <h2 className="main-title">Cloud Services</h2>
              <p className="content">
              Our flexible cloud solutions can accommodate the needs of developers, IT managers, and CIOs.      
              </p>
              
            </div>
        </div>
      </div>
    </div>
  );
}

export default SubBannerCloud;
