import React from "react";
import "./CuServiceAgile.scss";

function CuServiceAgile() {
  return (
    <div className="custom-service-agile page-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="sub-title" data-aos="zoom-in" data-aos-once="true">
              Agile
            </h2>
            <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
              Development
            </h2>
            <p className="content" data-aos="zoom-in" data-aos-once="true">
              Reduce time-to-market with our quick and agile development
              approach, which can be completed in 4-8 weeks. As a consequence,
              you'll have a fully working version of your product with all
              essential features available for user testing.
            </p>
          </div>
          <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
            <img
              src={require("../../images/Agile.png").default}
              alt="img"
              className="front-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CuServiceAgile;
