import React from "react";
import "./SubBannerUi.scss";
function SubBannerUi() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
      <div className="top-section">
          <div className="top-text">
              <h2 className="main-title">UI/UX Development</h2>
              <p className="content">
              Understanding your audience is a fundamental component of any 
              marketing effort.
              </p>
              
            </div>
        </div>
      </div>
    </div>
  );
}

export default SubBannerUi;
