import React from "react";
import ApplyQA from "../ApplyQA/ApplyQA";
import CuServiceJT from "../CuServiceJT/CuServiceJT";
import MetaConfigure from "../Helmet/Helmet";
import JobPositions from "../JobPositions/JobPositions";
import JoinTeam from "../JoinTeam/JoinTeam";
import OurBenifit from "../OurBenifit/OurBenifit";

import "./Careers.scss";

function Careers() {
  window.scrollTo(0, 0);
  return (
    <div className="career-section ">
      <MetaConfigure title="Careers - Join our team to highlight your future" />

      <JoinTeam />
      <CuServiceJT />
      <JobPositions />
      <ApplyQA />
      <OurBenifit />
    </div>
  );
}

export default Careers;
