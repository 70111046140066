import React from 'react'
import './WhyAxios.scss'

function WhyAxios() {
  return (
    <div className="whyaxios-section section-gap">
      <div className="container">
        <h2
          className="main-title mb-30"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Why Choose Axios
        </h2>

        <div className="row">
          <div
            className="col-md-6 col-xl-3 col-lg-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="mid-card-section  layer-bg-maroon">
              <div className="outer-img-card ">
                <div className="img-card ">
                  <img
                    src={require('../../images/Group.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <h2 className="sub-title">Quality</h2>
              <p className="content ">
                To ensure achievement of the best possible results in what we
                work for, we have the Production Team and the Quality Assurance
                team working independently, in collaboration with each other.
              </p>
            </div>
          </div>

          <div
            className="col-md-6 col-xl-3 col-lg-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="mid-card-section  layer-bg-skyblue">
              <div className="outer-img-card ">
                <div className="img-card ">
                  <img
                    src={require('../../images/Group.png').default}
                    alt="img"
                  />
                </div>
              </div>
              <h2 className="sub-title">Outcome</h2>
              <p className="content ">
                We work to our full ability in order to produce the most
                efficient and effective result possible, We can assure you
                better performance with reference to the valuable feedback from
                our clients.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-xl-3 col-lg-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="mid-card-section  layer-bg-purple layer-bg-blue">
              <div className="outer-img-card ">
                <div className="img-card ">
                  <img
                    src={require('../../images/Group.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <h2 className="sub-title">Custom Development</h2>
              <p className="content ">
                Your precious ideas are valuable to us and we work on them to
                achieve exactly what you desire. we assist you in developing
                unique features for your business based on your preferences and
                requirements.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-xl-3 col-lg-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="mid-card-section  layer-bg-orange">
              <div className="outer-img-card ">
                <div className="img-card ">
                  <img
                    src={require('../../images/Group.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <h2 className="sub-title">Developmental Support</h2>
              <p className="content ">
                Axios has a handpicked team of experts. We can provide an IT
                team for development tasks you need to perform. We provide 24/7
                support on the Backend, Frontend and server system for the
                companies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyAxios
