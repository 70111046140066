import React, { Component } from "react";
import Slider from "react-slick";
import "./Feedback.scss";

export default class ImageGallaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    var settings = {
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="main section-gap ">
        <div className="container">
          <div className="cont">
            <div className="main-box">
              <h5 class="main-title" data-aos="zoom-in" data-aos-once="true">
                Why customers love us ?
              </h5>
              <Slider
                autoplay
                asNavFor={this.state.nav2}
                ref={(slider) => (this.slider1 = slider)}
              >
                <div className="page" data-aos="zoom-in" data-aos-once="true">
                  <p class="content" data-aos="zoom-in" data-aos-once="true">
                    Nivid is the best School Management System I have used so
                    far. Customer support is also always available
                  </p>
                </div>
                <div className="page" data-aos="zoom-in" data-aos-once="true">
                  <p class="content">
                    I am Proud to say that, I have worked with Axios.
                  </p>
                </div>
                <div className="page" data-aos="zoom-in" data-aos-once="true">
                  <p class="content">
                    Bolpatra is Nepal's Best Tender Portal, Govermental
                    Tender/Private Tender/NGO-INGO Tender. It has only been
                    possible because of Axios
                  </p>
                </div>
              </Slider>
            </div>
            <div className="base">
              <Slider
                asNavFor={this.state.nav1}
                ref={(slider) => (this.slider2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                {...settings}
              >
                <div className="first-profile">
                  <div className="profile-content">
                    <div className="img-box">
                      <img
                        data-aos="zoom-in"
                        data-aos-once="true"
                        className="images"
                        src={require("../../images/Hira.jpg").default}
                        alt="img"
                      />
                    </div>
                    <div
                      className="content-box"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      <h5 className="sub-title">Hira Sharma Nepal</h5>
                      <p className="content"> Vidya Sagar E.B.S.H. School</p>
                    </div>
                  </div>
                </div>
                <div className="first-profile">
                  <div className="profile-content">
                    <img
                      data-aos="zoom-in"
                      data-aos-once="true"
                      className="images"
                      src={require("../../images/sista.jpg").default}
                      alt="img"
                      width="50px"
                    />
                    <div
                      className="content-box"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      <h5 className="sub-title">Sista Thapaliya</h5>
                      <p className="content">Aurora Traders Nepal</p>
                    </div>
                  </div>
                </div>
                <div className="first-profile">
                  <div className="profile-content">
                    <img
                      data-aos="zoom-in"
                      data-aos-once="true"
                      className="images"
                      src={require("../../images/Amar.jpg").default}
                      alt="img"
                      width="50px"
                    />
                    <div
                      className="content-box"
                      data-aos="zoom-in"
                      data-aos-once="true"
                    >
                      <h5 className="sub-title">Amar Pratap</h5>
                      <p className="content">Bolpatra Nepal</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
