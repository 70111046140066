import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
// import ellipse from "./Ellipse.png";
import { NavLink } from "react-router-dom";
import "./Navbar.scss";
import { Link } from "react-router-dom";

function Navbar() {
  // const [show, setShow] = useState(false);
  const navRef = React.useRef(null);
  const show= false;

  const onRemoveClick = (e) => {
    navRef.current.classList.remove("show");
  };
  
  return (
    <div className="navbar-section ">
      <div className="nav-bg">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light ">
            <a className="navbar-brand" href="/">
              <img src={require("../../images/logo.png").default} alt="img" />
              Axios <span className="red-content"> Softwork</span>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse  ${show ? "show" : ""}`}
              id="navbarNav"
              ref={navRef}
            >
              <ul className="navbar-nav navleft-auto">
                <li className="nav-item active">
                  <div className="dropdown">
                    <NavLink
                      exact
                      activeClassName="active"
                      className="nav-link  "
                      to="/"
                    >
                      Company
                      <span className="drop-icon">
                        <i class="bi bi-chevron-down"></i>
                      </span>
                    </NavLink>

                    <div class="dropdown-content">
                      <ul>
                        <Link to="/introduction">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">About Axios</p>
                          </li>
                        </Link>

                        {/* <Link to="/Team">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">Team</p>
                          </li>
                        </Link> */}
                        <Link to="/products">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content"> Our products</p>
                          </li>
                        </Link>

                        <Link to="/quotation">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content"> Request Quotation</p>
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown-service">
                    <NavLink className="nav-link " to="">
                      Services
                      <span className="drop-icon">
                        <i class="bi bi-chevron-down"></i>
                      </span>
                    </NavLink>

                    <div class="servicedrop-content">
                      <ul>
                        <Link to="/webdevelopment">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">
                              Custom Web Development
                            </p>
                          </li>
                        </Link>

                        <Link to="/mobileapp">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">
                              Mobile App Development
                            </p>
                          </li>
                        </Link>

                        <Link to="/userDevelopment">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">UI/UX Development</p>
                          </li>
                        </Link>
                        <Link to="/agile-development">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">Agile Development</p>
                          </li>
                        </Link>

                        <Link to="/cloud-service">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">Cloud Services</p>
                          </li>
                        </Link>
                        <Link to="/assurance-services">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">
                              Quality Assurance service
                            </p>
                          </li>
                        </Link>

                        <Link to="/viable-products">
                          <li className="drop-box" onClick={onRemoveClick}>
                            <div className="drop-icon">
                              <i class="bi bi-chevron-right"></i>
                            </div>
                            <p className="drop-content">
                              Minimum Viable Product Development
                            </p>
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    to="/careers"
                    onClick={onRemoveClick}
                  >
                    Careers
                  </NavLink>
                </li>

                <li className="nav-btns">
                  <NavLink className="nav-link " to="/contact-us">
                    <button className="main-btn" onClick={onRemoveClick}>
                      Contact us
                    </button>
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
