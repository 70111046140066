import React from 'react'
import './SubBannerAgile.scss'
function SubBannerAgile() {
  return (
    <div className="sub-banner-section">
      <div className="sub-banner-content">
        <div className="top-section">
          <div className="top-text">
            <h2 className="main-title">Agile Development</h2>
            <p className="content">
              Increased Flexibility with a Fast Failure Mindset.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubBannerAgile
