import React from "react";
import CentricProcess from "../CentricProcess/CentricProcess";
import CuServiceUi from "../CuServiceUi/CuServiceUi";
import SubBannerUi from "../SubBannerUi/SubBannerUi";
import UserCentric from "../UserCentric/UserCentric";
import "../Home/Home.scss";
import MetaConfigure from "../Helmet/Helmet";

function UserDevelopment() {
  return (
    <div className="banner-overflow">
      <MetaConfigure title="UserDevelopment-AxiosSoftworks" />

      <SubBannerUi />
      <CuServiceUi />
      <CentricProcess />
      <UserCentric />
    </div>
  );
}

export default UserDevelopment;
