import { Formik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { QuotationPage } from "../Api/PostApi";
const initialValues = {
  design: [],
  development: [],
  name: "",
  company_name: "",
  phone: "",
  email: "",
  description: "",
};

const validationSchema = Yup.object().shape({
  // design: Yup.array().required("Please select one of the project"),
  // development: Yup.array().required("Please select one of the skill"),
  name: Yup.string().required("please enter your name "),
  email: Yup.string().email().required("Please enter your email"),
  phone: Yup.string()
    .min(10, "Must be 10 chatracters")
    .required("Please enter your phone number"),

  company_name: Yup.string().required("Please enter your company name"),
  description: Yup.string().required("Please enter your message"),
});

export const FormikWrapper = ({ children }) => {
  const handleSubmit = (values, { resetForm }) => {
    QuotationPage(values).then((res) => {
      if ((res.status = 200)) {
        toast("successfully submitted");
        window.location.reload();
        resetForm({
          values: {
            design: [],
            development: [],
            name: "",
            company_name: "",
            phone: "",
            email: "",
            description: "",
          },
        });
      } 
    }).catch((err)=>{
      toast.error("network error")
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <>{children}</>
    </Formik>
  );
};
