import React from 'react'
import './OurBenifit.scss'

function OurBenifit() {
  window.scrollTo(0, 0)
  return (
    <div className="Benefits-section section-gap">
      <div className="container">
        <h2
          className="main-title mb-15"
          data-aos="zoom-in"
          data-aos-once="true"
        >
          Our Benefits
        </h2>
        <p className="benefits-content content">
          Axios is what it is because of our Team Members
        </p>

        <div className="row">
          <div
            className="col-md-12 col-lg-6  col-xl-6 "
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightpink lightpink-bg ">
              <div className="outer-img-card">
                <div className="img-card ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame.png').default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="weserve-content">
                <h2 className="sub-title "> Friendly Environment</h2>
                <p className="content">
                  An setting that makes you feel welcome and appreciated helps
                  you to concentrate only on your job and pushes you to create
                  high-quality results. We have people who are upbeat and kind.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section bg-lightblue lightblue-bg">
              <div className="outer-img-card">
                <div className="img-card  ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame (1).png').default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="weserve-content">
                <h2 className="sub-title "> Flexibility</h2>
                <p className="content">
                  Workplace flexibility emphasizes the willingness and ability
                  to adapt to change, particularly regarding how and when work
                  gets done. In a flexible workplace, the needs of both employee
                  and employer are met.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightgreen lightgreen-bg">
              <div className="outer-img-card">
                <div className="img-card ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame (2).png').default}
                    alt="img"
                  />
                </div>
              </div>
              <div className="weserve-content">
                <h2 className="sub-title ">
                  Team Building Activities and Retreats
                </h2>
                <p className="content">
                  For relaxation, we offer various trips and getaways on a
                  regular basis. The crew contributes other places to visit, and
                  we hit the road as soon as we're ready.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightpurple lightpurple-bg  ">
              <div className="outer-img-card">
                <div className="img-card  ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame (3).png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Driven by Common Vision</h2>
                <p className="content">
                  Having clear company values helps you ensure that all your
                  employees are working towards the same goals. Our team
                  believes in creating milestones and symbolizing support behind
                  the vision.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-6  col-xl-6 "
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section  bg-lightpink lightpink-bg ">
              <div className="outer-img-card">
                <div className="img-card ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame.png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">High Team Spirit</h2>
                <p className="content">
                  Team spirit is the feeling of pride and loyalty that exists
                  among our members of a team which motivates them to want their
                  team to succeed or be the best.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-12 col-lg-6 col-xl-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <div className="card-section bg-lightblue lightblue-bg">
              <div className="outer-img-card">
                <div className="img-card  ">
                  <img
                    className="logo-img"
                    src={require('../../images/webdev/Frame (1).png').default}
                    alt="img"
                  />
                </div>
              </div>

              <div className="weserve-content">
                <h2 className="sub-title ">Support for Growth</h2>
                <p className="content">
                  Axios offers recognition and rewards, real-time feedback than
                  just monthly reviews, mentoring and coaching, identifying and
                  developing soft skills, as well as timely encouragement and
                  support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurBenifit
