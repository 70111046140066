//@ts-check
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepperFirst from "../StepperFirst/StepperFirst";
import StepperSecond from "../StepperSecond/StepperSecond";
import StepperThird from "../StepperThird/StepperThird";
import "./Stepper.scss";
import { useFormikContext } from "formik";

const steps = [
  "Step 1:Project Type",
  "Step 2:Skill Scope",
  "Step 3:Contact Information",
];

export default function HorizontalNonLinearStepper() {

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});
  const [disableBtn, setDisableBtn] = React.useState(true);
  const { values, handleSubmit } = useFormikContext();

const handleSave = () => {
handleSubmit()
}

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const allStepsCompleted = () => {
    return Object.keys(completed).length === steps.length;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
React.useEffect(()=>{
  console.log("setDisableBtn",setDisableBtn)
},[])
  return (
    <div className="step-section">
      <div className="container">
        <Box sx={{ width: "100%" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={() => handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {activeStep === 0 ? (
                    <StepperFirst setDisableBtn={setDisableBtn} />
                  ) : activeStep === 1 ? (
                    <StepperSecond setDisableBtn={setDisableBtn} />
                  ) : activeStep === 2 ? (
                    <StepperThird />
                  ) : (
                    ""
                  )}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    pt: 2,
                  }}
                >
                  {activeStep !== 0 ? (
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{
                        mr: 1,
                        margin: "30px",
                        color: "#851c14db",
                        fontWeight: "600",
                      }}
                    >
                      <i className="bi bi-arrow-left"></i> Go Back
                    </Button>
                  ) : (
                    ""
                  )}

                  {activeStep !== 2 ? (
                    <Button
                      className="main-btn"
                      onClick={handleNext}
                      sx={{ mr: 1, margin: "30px" }}
                      disabled={disableBtn}
                    >
                      <div className="white-font">
                        Next Step<i className="bi bi-arrow-right"></i>
                      </div>
                    </Button>
                  ) : (
                    <button
                      className="main-btn"
                      type="submit"
                      onClick={() => {
                        console.log("final val", values);
                        handleSave()
                      }}
                    >
                      Submit
                    </button>
                  )}
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </div>
    </div>
  );
}
