import React, { useRef } from 'react'
import './Offer.scss'
import { toast } from 'react-toastify'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css'
import { CarrerPage } from '../Api/PostApi'

function Offer() {
  window.scrollTo(0, 0)
  const inputRef = useRef()

  return (
    <div id="contact" className="offer-section section-gap">
      <div className="container">
        <h2
          className="head-title line-height padding-bottom-0"
          data-aos="zoom-in"
        >
          We will find offer that fits you
        </h2>
        <h2 className="head-title padding-top-0" data-aos="zoom-in">
          best
        </h2>

        <div className="contact-content">
          <div className="card-section">
            <Formik
              initialValues={{
                fullname: '',
                email: '',
                number: '',
                location: '',
                position: '',
                cv: File,
              }}
              validationSchema={Yup.object({
                fullname: Yup.string()
                  .min(7, 'Please enter your full name')
                  .required('please enter your name '),
                email: Yup.string().email().required('Please enter your email'),

                number: Yup.string()
                  .min(10, 'Must be 10 chatracters')
                  .required('Please enter your phone number'),
                location: Yup.string().required('Please enter your location'),
                position: Yup.string().required('Please enter your position'),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                var formData = new FormData()
                formData.append('fullname', values.fullname)
                formData.append('email', values.email)
                formData.append('number', values.number)
                formData.append('location', values.location)
                formData.append('cv', values.cv)
                formData.append('position', values.position)

                CarrerPage(formData).then(({ data, resetForm }) => {
                  if ((data.status = 200)) {
                    toast('submitted')
                    resetForm({
                      values: {
                        fullname: '',
                        email: '',
                        number: '',
                        location: '',
                        position: '',
                        cv: File,
                      },
                    })
                  } else {
                    toast.error('something went wrong')
                  }
                })
                setSubmitting(false)
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="material-textfield">
                          <Field
                            name="fullname"
                            className="text-area"
                            type="text"
                            placeholder=" "
                          />
                          <label className="label-manage">Name</label>
                        </div>
                        <span className="error">
                          <ErrorMessage name="fullname" />
                        </span>
                      </div>
                      <div className="col-md-6">
                        <div className="material-textfield">
                          <Field
                            name="email"
                            className="text-area"
                            type="text"
                            placeholder=" "
                          />
                          <label className="label-manage">Email</label>
                        </div>
                        <span className="error">
                          <ErrorMessage name="email" />
                        </span>
                      </div>

                      <div className="col-md-6">
                        <div className="material-textfield">
                          <Field
                            name="number"
                            className="text-area"
                            type="text"
                            placeholder=" "
                          />
                          <label className="label-manage">Phone</label>
                        </div>
                        <span className="error">
                          <ErrorMessage name="number" />
                        </span>
                      </div>
                      <div className="col-md-6">
                        <div className="material-textfield">
                          <Field
                            name="location"
                            className="text-area"
                            type="text"
                            placeholder=" "
                          />
                          <label className="label-manage">Location</label>
                        </div>
                        <span className="error">
                          <ErrorMessage name="location" />
                        </span>
                      </div>

                      <div className="col-md-6">
                        <div className="CV-label">CV (PDF) up to 12 MB </div>
                        <div className="file-uploads">
                          <div className="mg-to">
                            <input
                              ref={inputRef}
                              className="pdf-upload"
                              type="file"
                              // id="fileUpload"
                              accept=".pdf"
                              name="cv"
                              onChange={(e) => {
                                if (e.target.files) {
                                  formik.setFieldValue('cv', e.target.files[0])
                                }
                              }}
                            />
                            {/* <label for="fileUpload">
                              <div className="input-deco"> */}

                            {/* </div>
                            </label> */}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="material-textfield position-mg">
                          <Field
                            name="position"
                            className="text-area"
                            type="text"
                            placeholder="Enter position you applying for "
                          />
                          <label className="label-manage">Position</label>
                        </div>
                        <span className="error">
                          <ErrorMessage name="position" />
                        </span>
                      </div>
                    </div>
                    <button type="submit" className="main-btn text-init mg">
                      Submit
                    </button>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Offer
