import React from "react";
import SubBanner from "../SubBanner/SubBanner";
import "./Products.scss";
import "../Home/Home.scss";
import MetaConfigure from "../Helmet/Helmet";

function Products() {
  return (
    <div className="banner-overflow">
      <MetaConfigure title="Our Products - Creations that we are known for" />

      <SubBanner />

      <div className="product-section section-gap">
        <div className="container">
          <div className="row  ">
            <div className="col-md-6 alignCenter">
              <div className="product-contain  text-init">
                <h1
                  className="main-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  NIVID
                </h1>

                <p
                  className="sub-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  A Complete School Management System
                </p>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>
                    Online Portal to conduct online classes and exams and to
                    track daily activities of both Teachers and Students
                  </p>
                </div>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>
                    Mobile Application for both Teacher and Parents for
                    communication and for flow of Notices
                  </p>
                </div>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>
                    Web App for the Admin panel to overview all the activities
                    of the school including Library, Canteen, Examination,
                    Accounting.
                  </p>
                </div>
                <a href="https://nivid.app/" target="_blank" rel="noreferrer">
                  <button className="main-btn">Check here</button>
                </a>
              </div>
            </div>

            <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
              <img
                className="fullwidth"
                src={require("../../images/product1.png").default}
                alt="img"
              />
            </div>
          </div>

          <div className="row mgt-30 text-init">
            <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
              <img
                className="fullwidth"
                src={require("../../images/product/Mask Group (1).png").default}
                alt="img"
              />
            </div>

            <div className="col-md-6 alignCenter">
              <div className="product-contain  ">
                <h1
                  className="main-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  Dabali Store
                </h1>

                <p
                  className="sub-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  Online Platform to create an Ecommerce
                </p>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>
                    Build Traditional Ecommerce Website in less than 5 minutes
                  </p>
                </div>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>Choose Responsive Templates with top designs</p>
                </div>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>If necessary, add additional features, if not remove</p>
                </div>
                <a
                  href="https://dabali.store/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="main-btn">Check here</button>
                </a>
              </div>
            </div>
          </div>

          <div className="row  mgt-30 text-init">
            <div className="col-md-6 alignCenter">
              <div className="product-contain  ">
                <h1
                  className="main-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  Hisaav
                </h1>

                <p
                  className="sub-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  An Inventory Management System
                </p>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>
                    Purchase Sales, Profit and loss report, data analysis and
                    visualization
                  </p>
                </div>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>Bill Management (buy and Sales of goods) and print</p>
                </div>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>Stock management</p>
                </div>
                <a
                  href="https://hisaav.com/users/sign_in"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="main-btn">Check here</button>
                </a>
              </div>
            </div>

            <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
              <img
                className="fullwidth"
                src={require("../../images/product/Mask Group.png").default}
                alt="img"
              />
            </div>
          </div>

          <div className="row mgt-30 text-init ">
            <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
              <img
                className="fullwidth"
                src={require("../../images/product/img11.png").default}
                alt="img"
              />
            </div>

            <div className="col-md-6 alignCenter">
              <div className="product-contain ">
                <h1
                  className="main-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  CallXon
                </h1>

                <p
                  className="sub-title"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  Systematic approach to track your leads
                </p>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>
                    Integrates lead management, task funneling and remote
                    calling in single platform
                  </p>
                </div>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>Call Customers with one button</p>
                </div>

                <div className="d-flex" data-aos="zoom-in" data-aos-once="true">
                  <i class="bi bi-check-lg"></i>
                  <p>Keeps Records of all the customers</p>
                </div>
                <a
                  href="https://www.callxon.com/#features"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="main-btn">Check here</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
