import React from "react";
import "./CuServiceJT.scss";

function CuServiceJT() {
  return (
    <div className="our-benifit-service page-gap text-init">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="sub-title" data-aos="zoom-in" data-aos-once="true">
              You Shape
            </h2>
            <h2 className="main-title" data-aos="zoom-in" data-aos-once="true">
              The Future
            </h2>
            <p className="content" data-aos="zoom-in" data-aos-once="true">
              We are looking for talented and motivated people.
            </p>
          </div>
          <div className="col-md-6" data-aos="zoom-in" data-aos-once="true">
            <img
              src={require("../../images/ourbenifit.png").default}
              alt="img"
              className="front-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CuServiceJT;
